import React from 'react'
import { connect } from 'react-redux'
import { Modal, Image, Header, Divider } from 'semantic-ui-react'

import { ManualUserPasswordForm } from './ManualSignInForm'
import { ManualRegistration } from './ManualRegistrationForm'
import { PasswordForgetForm } from './PasswordForgetForm'
import { SocialSignIn } from './SocialSignIn'

import { userHistoryActions } from '../../_actions'
import { history } from '../../_helpers'

import './userLogins.css'

const WINDOW_STATES = {
  signIn: 'SIGN_IN',
  signUp: 'SIGN_UP',
  forgotPassword: 'FORGOT_PASSWORD'
}

const INITIAL_STATE = {
  modalOpen: false,
  windowState: WINDOW_STATES.signIn,
  error: undefined
}

class UnconnectedLoginWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.openableTrigger = props.openableTrigger
    this.state = { ...INITIAL_STATE }
  }

  handleOpen = () => {
    this.setState({
      modalOpen: true,
      windowState: WINDOW_STATES.signIn
    })
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  toggleWindowToState = state => () => {
    this.setState({ windowState: state })
  }

  onSuccessfulLogin = (response) => {
    const { fetchUserHistory, redirectTo } = this.props
    const { user } = response

    if (user) {
      this.handleClose()
      fetchUserHistory(user.uid)
    }

    if (redirectTo) {
      history.push(redirectTo)
    }
  }

  render() {
    const { windowState } = this.state
    const openableTrigger = this.openableTrigger(this.handleOpen)
    const toggleToSignIn = this.toggleWindowToState(WINDOW_STATES.signIn)
    const toggleToSignUp = this.toggleWindowToState(WINDOW_STATES.signUp)
    const toggleToForgotPassword = this.toggleWindowToState(WINDOW_STATES.forgotPassword)

    let contentToShow = null
    if (windowState === WINDOW_STATES.signIn) {
      contentToShow = <LoginWindow
        toggleToSignUp={toggleToSignUp}
        toggleToForgotPassword={toggleToForgotPassword}
        onSuccessfulLogin={this.onSuccessfulLogin}
      />
    } else if (windowState === WINDOW_STATES.signUp) {
      contentToShow = <SignUpWindow
        toggleToSignIn={toggleToSignIn}
        onSuccessfulLogin={this.onSuccessfulLogin}
      />
    } else {
      contentToShow = <ForgotPasswordWindow
        toggleToSignIn={toggleToSignIn}
      />
    }

    return (
      <Modal
        trigger={openableTrigger}
        centered={false}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size='small'
      >
        <div>
          <Image className='modal-logo' centered src='/assets/images/logo_purple.png' size='tiny' />
          {contentToShow}
        </div>
      </Modal>
    )
  }
}

const mapDispatchToProps = {
  fetchUserHistory: userHistoryActions.fetchHistory
}

export const LoginWrapper = connect(null, mapDispatchToProps)(UnconnectedLoginWrapper)

function LoginWindow(props) {
  return (
    <div>
      <Header className='login-header' textAlign='center' size='medium'>Sign In</Header>

      <ManualUserPasswordForm
        handleClose={props.handleClose}
        toggleToSignUp={props.toggleToSignUp}
        toggleToForgotPassword={props.toggleToForgotPassword}
        onSuccessfulLogin={props.onSuccessfulLogin}
      />
      <Divider className='login-divider' horizontal>Or</Divider>

      <div className='social-login-wrapper partial-width'>
        <SocialSignIn onSuccessfulLogin={props.onSuccessfulLogin} />
      </div>

      <div className='forgot-password-container'>
        <button onClick={props.toggleToForgotPassword} className='no-style-button'>Forgot Password?</button>
      </div>
    </div>
  )
}

function SignUpWindow(props) {
  return (
    <div className='sign-up-wrapper'>
      <Header className='login-header' textAlign='center' size='medium'>Sign Up</Header>
      <ManualRegistration
        handleClose={props.handleClose}
        toggleToSignIn={props.toggleToSignIn}
        onSuccessfulLogin={props.onSuccessfulLogin}
      />
    </div>
  )
}

function ForgotPasswordWindow(props) {
  return (
    <div className='sign-up-wrapper'>
      <Header className='login-header' textAlign='center' size='medium'>Forgot Password?</Header>
      <PasswordForgetForm handleClose={props.handleClose} toggleToSignIn={props.toggleToSignIn} />
    </div>
  )
}