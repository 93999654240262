import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withFirebase } from 'react-redux-firebase'

export const connectToUserSession = Component => connect(
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile
  }))(Component)

export const connectToFirebaseAuth = Component => compose(
  withFirebase,
  connect(({ firebase: { auth } }) => ({ auth }))
)(Component)

export const connectToUserHistory = Component => connect(
  ({ userHistory }) => ({
    userHistory: userHistory ? userHistory.results : undefined,
  }))(Component)

export const connectToLastUserInput = Component => connect(
  ({ userHistory }) => ({
    lastUserInput: (userHistory && userHistory.results) ?
      userHistory.results[0] :
      undefined
  }))(Component)

export const connectToCurrentFilters = Component => connect(
  ({ currentFilters }) => ({
    currentFilters
  }))(Component)
