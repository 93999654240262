import React from 'react'
import ReactGA from 'react-ga'
import { Header, Form } from 'semantic-ui-react'
import { compose } from 'recompose'

import { userHistoryActions } from '../_actions'
import { trackableEvents } from '../_constants'
import { RestApi } from '../_services'
import { history, connectToUserSession, connectToLastUserInput, hasAuthenticatedUser } from '../_helpers'
import { TitledPage, ButtonWithDownArrow, PageView } from '../_components'

import './DataFormPage.css'

export class DataFormPage extends React.Component {
    componentDidMount() { PageView() }

    render() {
        return (
            <div id='filter-page'>
                <TitledPage
                    title='YOUR HEALTH CHECK'
                    subtitles={[
                        "The first step towards improving your business tomorrow is understanding where you stand today. Based on decades of experience and data from over 280K companies, we've identified several key metrics that are the best leading indicators of long-term success.",
                        "Fill this form out to get started. And remember, your data is anonymous by default and always confidential."
                    ]}
                    subtitleWidth='80%'
                    darkPage={true}
                >
                    <InputForm />
                </TitledPage>
            </div>
        )
    }
}

const MILLION = 1000000

class UnconnectedInputForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            yearFounded: undefined,
            capRaisedData: undefined,
            revenue: undefined,
            empCount: undefined,
            yoyGrowth: undefined,
            burn: undefined,
            email: undefined,
            inputErrors: {},
        }
    }

    // TODO: Move this into a translation layer
    formatUserProvidedData(inputData) {
        return {
            year_founded: inputData.yearFounded,
            revenue: inputData.revenue,
            yoy_growth: inputData.yoyGrowth,
            cap_raised: inputData.capRaisedData,
            num_employees: inputData.empCount,
            burn_rate: inputData.burn,
            email: inputData.email
        }
    }

    handleSubmit = () => {
        const { auth, dispatch } = this.props
        const errors = validateData(this.state)
        if (Object.keys(errors).length === 0) {
            var currentState = this.state
            currentState = {
                ...currentState,
                capRaisedData: currentState.capRaisedData * MILLION,
                revenue: currentState.revenue * MILLION,
                burn: currentState.burn * MILLION,
            }

            const formattedData = this.formatUserProvidedData(currentState)

            const restApi = new RestApi(auth)
            restApi.saveUserData(formattedData)
                .then(() => {
                    dispatch(userHistoryActions.addNewSubmission(formattedData))

                    if (!auth.isEmpty) {
                        dispatch(userHistoryActions.fetchHistory(auth.uid))
                    }

                    openResultsPage(currentState)
                })

            ReactGA.event({
                category: trackableEvents.SUBMIT_INFO,
                action: `Successful data submission`,
            })
        }

        this.setState({ inputErrors: errors })
    }

    handleChange = (event, data) => {
        const { name, value } = data
        this.setState({
            [name]: value
        })
    }

    render() {
        const { yearFounded, capRaisedData, revenue, empCount, yoyGrowth, burn, email, inputErrors } = this.state
        const isValidForm = yearFounded && capRaisedData && revenue && empCount && yoyGrowth && burn
        const userSignedIn = hasAuthenticatedUser(this.props.auth)

        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    < Header
                        id="filter-subheader"
                        className="white-text"
                        content='YOUR DATA:'
                    />
                    <Form.Group widths='equal'>
                        <Form.Input type='number' step='any' error={inputErrors['yearFounded']} name='yearFounded' value={yearFounded} onChange={this.handleChange} placeholder="Year Founded?" />
                        <Form.Input type='number' step='any' error={inputErrors['capRaisedData']} name="capRaisedData" value={capRaisedData} onChange={this.handleChange} placeholder='Total Capital Raised? ($ in millions)' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input type='number' step='any' error={inputErrors['revenue']} name="revenue" value={revenue} onChange={this.handleChange} placeholder='Annual Revenue? ($ in millions)' />
                        <Form.Input type='number' step='any' error={inputErrors['empCount']} name="empCount" value={empCount} onChange={this.handleChange} placeholder='Number of Employees?' />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Input type='number' step='any' error={inputErrors['yoyGrowth']} name="yoyGrowth" value={yoyGrowth} onChange={this.handleChange} placeholder='YoY Growth %?' />
                        <Form.Input type='number' step='any' error={inputErrors['burn']} name="burn" value={burn} onChange={this.handleChange} placeholder='Monthly Burn Rate? ($ in millions)' />
                    </Form.Group>
                    <p id="form-disclaimer">*All data remains confidential</p>
                    <div className="email-submit-box">
                        {userSignedIn ? undefined :
                            <Form.Group widths="equal">
                                <Form.Input type='text' step='any' error={inputErrors['email']} name="email" value={email} onChange={this.handleChange} placeholder='(Optional) Email' />
                            </Form.Group>}
                    </div>

                    <div className='results-button'>
                        <ButtonWithDownArrow
                            darkButton={true}
                            disabled={!isValidForm}
                            content="SEE RESULTS"
                            buttonCentered={true}
                        />
                    </div>
                </Form >
            </div>
        )
    }
}



const InputForm = compose(
    connectToUserSession,
    connectToLastUserInput)(UnconnectedInputForm)

function validateData(inputData) {
    let errors = {}

    const { yearFounded, capRaisedData, revenue, empCount, yoyGrowth, burn } = inputData

    if (!yearFounded || yearFounded == null || yearFounded === "") {
        errors['yearFounded'] = "Year Founded cannot be empty."
    } else if (yearFounded < 1900 || yearFounded > 2020) {
        errors['yearFounded'] = "Year Founded must be between 1900 and 2020"
    }

    if (!revenue || revenue == null || revenue === "") {
        errors['revenue'] = "Revenue cannot be empty."
    } else if (revenue < 0 || revenue > 10000) {
        errors['revenue'] = "Revenue must be between 0 and 10000 (in millions)"
    }

    if (!yoyGrowth || yoyGrowth == null || yoyGrowth === "") {
        errors['yoyGrowth'] = "YoY Growth cannot be empty."
    } else if (yoyGrowth < 0 || yoyGrowth > 10000) {
        errors['yoyGrowth'] = "YoY Growth must be between -100% and 1000%"
    }

    if (!capRaisedData || capRaisedData == null || capRaisedData === "") {
        errors['capRaisedData'] = "Total Capital Raised cannot be empty."
    } else if (capRaisedData < 0 || capRaisedData > 10000) {
        errors['capRaisedData'] = "Total Capital Raised must be between 0 and 10000 (in millions)"
    }

    if (!empCount || empCount == null || empCount === "") {
        errors['empCount'] = "Employee count cannot be empty."
    } else if (empCount < 1 || empCount > 10000) {
        errors['empCount'] = "Employee count must be between 1 and 10000"
    }

    if (!burn || burn == null || burn === "") {
        errors['burn'] = "Burn cannot be empty."
    } else if (burn < -10 || burn > 100) {
        errors['burn'] = "Monthly Burn must be between -10 and 100 (in millions)"
    }

    return errors
}

export function getResultsPageUrl(inputData) {
    const { yearFounded, capRaisedData, revenue, empCount, yoyGrowth, burn } = inputData

    let baseFrame = "/results?"

    let param = "yearFounded=" + yearFounded
    param += "&revenue=" + revenue
    param += "&yoyGrowth=" + yoyGrowth
    param += "&capRaisedData=" + capRaisedData
    param += "&empCount=" + empCount
    param += "&burn=" + burn

    baseFrame = baseFrame + "p=" + encodeURIComponent(btoa(param))
    return baseFrame
}

function openResultsPage(inputData) {
    const url = getResultsPageUrl(inputData)
    history.push(url)
}