import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import ReactGA from 'react-ga'

import { store, persistor, CompanyDataProvider } from './_helpers'
import { getFirebaseProps } from './_services'

import App from './App'

const firebaseProps = getFirebaseProps()

ReactGA.initialize("UA-126214747-3")

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ReactReduxFirebaseProvider {...firebaseProps}>
        <CompanyDataProvider>
          <App />
        </CompanyDataProvider>
      </ReactReduxFirebaseProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)