import React from 'react'
import { isMobile } from 'react-device-detect'

import { PlotlyWrapper } from './plotlyWrapper'
import { colorConstants } from '../../_constants'

export function ComparisonChart({ percentileRanks }) {
  const plotHeight = isMobile ? 440 : 460
  const textFontSize = isMobile ? 12 : 12
  const tickAngle = isMobile ? -60 : -20
  const labels = isMobile ? ["Rev. w/in Age", "YoY Growth", "Rev / Cap Raised", "Cap Efficiency", "Rev / Employee"] : ["Rev. w/in Age", "YoY Growth", "Rev. w/in Cap Raised", "Cap Efficiency", "Rev / Employee"]
  const bMargin = isMobile ? 120 : 90
  const lMargin = isMobile ? 50 : 70
  const rMargin = isMobile ? 20 : 40

  const plotData = {
    x: labels,
    y: Object.values(percentileRanks),
    text: Object.values(percentileRanks).map(v => '<b>' + String(v) + '</b>%'),
    textposition: 'auto',
    hoverinfo: 'none',
    type: 'bar',
    textfont: {
      size: textFontSize,
      bold: true
    },
    marker: {
      color: colorConstants.Toolbox,
    }
  }

  const chartDescription = {
    data: [plotData],
    layout: {
      yaxis: {
        title: {
          text: 'Percentile Rank',
          font: {
            bold: true,
            size: textFontSize
          }
        },
        range: [0, 101],
        ticktext: ["0", "", "20", "", "40", "", "60", "", "80", "", "100"],
        tickvals: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
      },
      xaxis: {
        tickangle: tickAngle,
        tickfont: {
          size: textFontSize
        }
      },
      margin: {
        l: lMargin,
        r: rMargin,
        b: bMargin,
        t: 70,
        pad: 2
      },
      height: plotHeight
    }
  }

  return (
    <PlotlyWrapper chartDescription={chartDescription} />
  )
}