import { isValidNum } from '../_components/charts/chartUtilities'
import {
    CapRaisedFilter,
    AgeFilter,
    SectorFilter,
    ValuationFilter,
    UserProvidedCapRaisedFilter,
} from './dataFilters'

export class MetricsRanker {
    constructor(userProvidedData, rawData, currentFilters) {
        this.rawData = rawData
        this.userProvidedData = userProvidedData

        this.capRaisedFilter = new CapRaisedFilter(currentFilters.capRaised)
        this.ageFilter = new AgeFilter(currentFilters.age)
        this.sectorFilter = new SectorFilter(currentFilters.sector)
        this.valuationFilter = new ValuationFilter(currentFilters.valuation)
        this.userProvidedCapRaisedFilter = new UserProvidedCapRaisedFilter(userProvidedData.cap_raised)
        this.defaultFilters = [this.capRaisedFilter, this.ageFilter, this.sectorFilter, this.valuationFilter, this.userProvidedCapRaisedFilter]
        this.metricWeights = {
            pointInTimeRevenue: 0.2,
            yoyGrowth: 0.3,
            revenueWithinCapRaised: 0.2,
            capEfficiency: 0.2,
            revPerEmployee: 0.1
        }
    }

    getPercentileRanks = () => {
        if (!this.rawData || this.rawData.length === 0) { return {} }

        const { revenue, yoy_growth, year_founded, num_employees, burn_rate } = this.userProvidedData
        const dataWithValidRevenue = this.rawData.filter(row => isValidNum(row.revenue))
        const dataAfterAllUserFilters = this._getFilteredData(this.rawData, this.defaultFilters)

        const pointInTimeRevenueFilters = [this.capRaisedFilter, this.valuationFilter, this.sectorFilter]
        const validPointInTimeRevenueData = this._getFilteredData(dataWithValidRevenue, pointInTimeRevenueFilters)
            .filter(row => +row.age === new Date().getFullYear() - +year_founded)
            .map(row => +row.revenue)
        const pointInTimeRevenueRank = this._calculatePercentileRank(validPointInTimeRevenueData, +revenue)

        const validYoyData = dataAfterAllUserFilters.filter(row => isValidNum(row.yoyGrowth)).map(row => +row.yoyGrowth)
        const yoyGrowthRank = this._calculatePercentileRank(validYoyData, +yoy_growth)

        const revenueWithinCapRaisedFilters = [this.ageFilter, this.valuationFilter, this.sectorFilter, this.userProvidedCapRaisedFilter]
        const revenueWithinCapRaisedData = this._getFilteredData(dataWithValidRevenue, revenueWithinCapRaisedFilters).map(row => +row.revenue)
        const revenueWithinCapRaisedRank = this._calculatePercentileRank(revenueWithinCapRaisedData, +revenue)

        const capEfficiency = (+revenue * +yoy_growth / 100) / +burn_rate
        const validCapEffiencyData = dataAfterAllUserFilters.filter(row => isValidNum(row.capEfficiency)).map(row => +row.capEfficiency)
        const capEfficiencyRank = this._calculatePercentileRank(validCapEffiencyData, capEfficiency)

        const userProvidedRevPerEmployee = +revenue / +num_employees
        const validRevPerEmployeeData = dataAfterAllUserFilters
            .filter(row => isValidNum(row.revenue) && isValidNum(row.numEmployees))
            .map(row => (+row.revenue / +row.numEmployees))
        const revPerEmployeeRank = this._calculatePercentileRank(validRevPerEmployeeData, userProvidedRevPerEmployee)

        const percentileRanks = {
            pointInTimeRevenue: pointInTimeRevenueRank,
            yoyGrowth: yoyGrowthRank,
            revenueWithinCapRaised: revenueWithinCapRaisedRank,
            capEfficiency: capEfficiencyRank,
            revPerEmployee: revPerEmployeeRank
        }

        return percentileRanks
    }

    getOverallRank = () => {
        const allPercentileRanks = this.getPercentileRanks()
        const reducer = (total, current) => {
            return total + allPercentileRanks[current] * this.metricWeights[current]
        }

        const weightedRank = Object.keys(allPercentileRanks).reduce(reducer, 0)
        const roundedRank = Math.ceil(weightedRank)
        return roundedRank
    }

    _calculatePercentileRank = (dataSet, providedDataPoint) => {
        if (!dataSet) { return 0.0 }

        dataSet.push(providedDataPoint)
        const sortNumerically = (a, b) => a - b
        const index = dataSet.sort(sortNumerically).indexOf(providedDataPoint)
        const percentileRank = 100 * ((index + 1) / dataSet.length)

        //TODO: Clean this up if we get more data coming in
        const randomChange = Math.random() * (3.0 - 0.1) + 0.1;
        const unroundedRank = percentileRank === 100.0 ? percentileRank - randomChange : percentileRank
        const roundedPercentileRank = unroundedRank.toFixed(1)
        return roundedPercentileRank
    }

    _getFilteredData = (data, filters) => {
        const filteredData = data.filter((row) => {
            let shouldIncludeInSet = true
            for (let i = 0; i < filters.length; i++) {
                const filter = filters[i]
                shouldIncludeInSet = shouldIncludeInSet && filter.shouldIncludeInSet(row)
            }

            return shouldIncludeInSet
        })

        return filteredData
    }
}