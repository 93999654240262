import React from 'react'
import ReactGA from 'react-ga'
import { Form } from 'semantic-ui-react'

import { trackableEvents } from '../_constants'
import { connectToCurrentFilters } from '../_helpers'
import { currentFiltersActions } from '../_actions'

const filterOptionsMap = {
    sector: [
        { value: -1, text: 'Any' },
        { value: 0, text: 'SaaS' },
        { value: 1, text: 'Other Software' },
        { value: 2, text: 'E-Commerce' },
        { value: 3, text: 'Internet' },
        { value: 4, text: 'Other Technology' }
    ],
    age: [
        { value: -1, text: 'Any' },
        { value: 0, text: '<2 years' },
        { value: 1, text: '2-5 years' },
        { value: 2, text: '5-10 years' },
        { value: 3, text: '10+ years' }
    ],
    valuation: [
        { value: -1, text: 'Any' },
        { value: 0, text: '<$100m' },
        { value: 1, text: '$100m - $500m' },
        { value: 2, text: '$500m+' }
    ],
    capRaised: [
        { value: -1, text: 'Any' },
        { value: 0, text: 'None' },
        { value: 1, text: '<$25m' },
        { value: 2, text: '$25-100m' },
        { value: 3, text: '$100m+' }
    ]
}

class UnconnectedCompanyFilter extends React.Component {
    handleChange = (event, data) => {
        const { dispatch } = this.props
        const { name, value } = data
        ReactGA.event({
            category: trackableEvents.ADD_FILTERS,
            action: `Added or changed filters - ${name}`,
        })

        dispatch(currentFiltersActions.setFilter(name, value))
    }

    render() {
        const { sector, age, valuation, capRaised } = this.props.currentFilters

        return (
            <div className="company-filter-form">
                <Form>
                    <Form.Group widths="equal">
                        <Form.Select
                            label='Sector:'
                            name="sector"
                            options={filterOptionsMap.sector}
                            value={sector}
                            onChange={this.handleChange}
                        />
                        <Form.Select
                            label='Age:'
                            name="age"
                            options={filterOptionsMap.age}
                            value={age}
                            onChange={this.handleChange}
                        />
                        <Form.Select
                            label='Valuation:'
                            name="valuation"
                            options={filterOptionsMap.valuation}
                            value={valuation}
                            onChange={this.handleChange}
                        />
                        <Form.Select
                            label='Capital Raised:'
                            name="capRaised"
                            options={filterOptionsMap.capRaised}
                            value={capRaised}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                </Form>
            </div>

        )
    }
}

export const CompanyFilter = connectToCurrentFilters(UnconnectedCompanyFilter)

