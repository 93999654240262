import { config } from '../_config'

class RestApi {
  constructor(auth) {
    this.auth = auth
    this.restApiBase = config.restApiBaseUrl
    this.restApiKey = config.restApiKey
  }

  saveUserData(userProvidedData) {
    const uid = this.auth.isEmpty ? '' : this.auth.uid
    const postBody = {
      ...userProvidedData,
      uid
    }

    return this._postJson({ restApiPath: '/metrics', postBody })
      .catch(error => console.warn('Possible unexpected error: ' + error))
  }

  makeEmailRequest(dataUrl, linkTarget) {
    const emailAddress = this.auth ? this.auth.email : ''

    const postBody = {
      "send_to": emailAddress,
      "link_target": linkTarget,
      "b64_image": dataUrl.split('data:image/png;base64,')[1]
    }

    this._postJson({ restApiPath: '/resultsEmail', postBody })
      .catch(error => console.warn('Possible unexpected error: ' + error))
  }

  getHistoricalData(uid) {
    const resultsPromise = this._postJson({ restApiPath: '/historicalUserData', postBody: { uid } })
      .then(response => response.json())

    return resultsPromise
  }

  _postJson({ restApiPath, additionalHeaders, postBody }) {
    const body = JSON.stringify(postBody)
    const headers = new Headers({
      'Accept': '*/*',
      'Content-Type': 'application/json',
      'Connection': 'keep-alive',
      'X-Api-Key': this.restApiKey,
      ...additionalHeaders
    })

    const options = {
      method: 'POST',
      mode: 'cors',
      headers,
      body
    }

    const url = this.restApiBase + restApiPath
    return fetch(url, options)
  }
}

export { RestApi }