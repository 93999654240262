export const config = {
    dataFileLocation: '/assets/data/mvpdata.csv',
    restApiBaseUrl: process.env.REACT_APP_REST_API_BASE_URL,
    restApiKey: process.env.REACT_APP_REST_API_KEY,
    firebase: {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_DATABASE_URL,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scopes: [
            "email",
            "profile"
        ]
    }
}