import React from 'react'

import { PlotlyWrapper } from './plotlyWrapper'

import { colorConstants } from '../../_constants'

export function UnicornMetricChart({ percentileRank }) {
  const data = [{
    values: [percentileRank, 100 - percentileRank],
    marker: {
      colors: [colorConstants.WhiteSmoke, colorConstants.LightToolbox]
    },
    textinfo: 'none',
    hoverinfo: 'none',
    hole: .85,
    direction: 'clockwise',
    type: 'pie',
  }]

  const unicornImage = {
    x: 0.35,
    y: 0.25,
    sizex: 0.28,
    sizey: 0.49,
    sizing: 'stretch',
    xref: 'paper',
    yref: 'paper',
    layer: 'above',
    xanchor: 'left',
    yanchor: 'bottom',
    source: '/assets/images/unicorn_icon.png'
  }

  const layout = {
    images: [unicornImage],
    showlegend: false,
    height: 120,
    plot_bgcolor: colorConstants.WhiteSmoke,
    paper_bgcolor: colorConstants.Toolbox,
    margin: {
      l: 10,
      r: 10,
      b: 10,
      t: 10,
    },
  }

  const chartDescription = { layout, data }

  return (
    <PlotlyWrapper chartDescription={chartDescription} />
  )
}
