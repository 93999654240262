import React from 'react'
import ReactGA from 'react-ga'
import { Button, Icon, Message } from 'semantic-ui-react'

import { connectToFirebaseAuth } from '../../_helpers'
import { trackableEvents } from '../../_constants'

class UnconnectedSocialSignIn extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null }
  }

  onSubmit = provider => event => {
    event.preventDefault()

    const { firebase, onSuccessfulLogin } = this.props
    firebase.login({ provider, type: 'popup' })
      .then(response => {
        onSuccessfulLogin(response)

        ReactGA.event({
          category: trackableEvents.LOGIN,
          action: `Successful ${provider} login`,
        })
      })
      .catch(error => {
        this.setState({ error })
      })
  }

  render() {
    const { error } = this.state

    return (
      <div>
        {error && <Message error header='Social Login Failed' content={error ? error.message : ''} />}
        <Button id='facebook-login' onClick={this.onSubmit('facebook')} color='facebook'>
          <Icon name='facebook' /> Sign In with Facebook
        </Button>
        <Button id='google-login' onClick={this.onSubmit('google')} color='google plus'>
          <Icon name='google plus' /> Sign In with Google
        </Button>
      </div>
    )
  }
}

const SocialSignIn = connectToFirebaseAuth(UnconnectedSocialSignIn)
export { SocialSignIn }
