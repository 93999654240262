import React from 'react'
import {
  Grid,
  Header,
  Image,
} from 'semantic-ui-react'

import { ROUTES, colorConstants } from '../_constants'
import { directToExternalSite, } from '../_helpers'
import { ImagePage, PageTitle, ButtonWithDownArrow, PageView } from '../_components'

import './AboutPage.css'


export class AboutPage extends React.Component {
  componentDidMount() { PageView() }

  render() {
    return (
      <ImagePage id="welcome-page" fluid>
        <PageTitle
          id="about-title"
          content='ABOUT US'
          darkText={true}
        />
        <div className='about-grid'>
          <Grid columns={3} divided relaxed padded stackable>
            <Grid.Row>
              <Grid.Column>
                <AboutItem
                  image='/assets/images/vc-icon.png'
                  title='GROWTH EQUITY'
                  text="Full In Partners is a growth equity firm changing the traditional capital model"
                />
              </Grid.Column>
              <Grid.Column>
                <AboutItem
                  image='/assets/images/team-icon.png'
                  title='EMPOWERMENT'
                  text="We fundamentally believe in empowering founders to build great businesses"
                />
              </Grid.Column>
              <Grid.Column>
                <AboutItem
                  image='/assets/images/equality-icon.png'
                  title='LEVEL PLAYING FIELD'
                  text="We believe founders should have the same access regardless of location or background"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <AboutItem
                  image='/assets/images/tools-icon.png'
                  title='FOUNDER-FOCUSED TOOLS'
                  text="We've built this tool among others to educate and support founders on their journey"
                />
              </Grid.Column>
              <Grid.Column>
                <AboutItem
                  image='/assets/images/data-icon.png'
                  title='DATA DRIVEN MODELS'
                  text="We've compiled data-driven models on over 280K companies from almost a dozen sources"
                />
              </Grid.Column>
              <Grid.Column>
                <AboutItem
                  image='/assets/images/lessons-icon.png'
                  title='YEARS OF EXPERIENCE'
                  text="We've based our understanding on 20+ years of experience working with great technology startups"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <div className='about-button'>
          <ButtonWithDownArrow
            content="LEARN MORE ABOUT US"
            onClick={() => directToExternalSite(ROUTES.FULLINHOME)}
            darkButton={false}
            buttonCentered={false}
          />
        </div>
      </ImagePage>
    )
  }
}

/*
<PageSubtitle
  content="So, we set out to build a suite of tools that could enable entrepreneurs
  to gain a better perspective on how to grow their businesses. We've taken our
  experience from over 20+ years in the technology startup landscape and data from
  over 280K companies to gain unique insights into what it takes to be successful. We've
  distilled those perspectives down to a few key points that we believe are the biggest
  indicators of a successful technology business regardless of sector or industry."
  darkText={true}
/>
 */


const aboutTitleStyle = {
  fontSize: 12,
  fontFamily: "Arial Black, Gadget, sans-serif",
  color: colorConstants.EerieBlack,

}

const aboutTextStyle = {
  fontSize: 12,
  fontFamily: "Trebuchet MS, Helvetica, sans-serif",
  lineHeight: '1.2em',
  color: colorConstants.EerieBlack
}

export function AboutItem({ image, title, text }) {
  return (
    <div>
      <Image src={image} size='mini' circular centered />
      <Header centered style={aboutTitleStyle} content={title} textAlign='center' />
      <Header style={aboutTextStyle} content={text} textAlign='center' />
    </div>

  )
}