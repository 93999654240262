import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from '../_reducers'

const INITIAL_STATE = {
    userHistory: {
        results: []
    },
    currentFilters: {
        sector: -1,
        age: -1,
        valuation: -1,
        capRaised: -1
    }
}

const createReduxStore = (initialState = {}, history) => {
    const persistConfig = {
        key: 'root',
        storage: storage,
    }

    const middleware = [
        thunkMiddleware
    ]

    const composeEnhancers =
        typeof window === 'object' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            }) : compose

    const enhancer = composeEnhancers(
        applyMiddleware(...middleware)
    )

    const persistedReducer = persistReducer(persistConfig, rootReducer)
    const store = createStore(persistedReducer, initialState, enhancer)
    const persistor = persistStore(store)
    return { store, persistor }
}

const { store, persistor } = createReduxStore(INITIAL_STATE)
export { store, persistor }
