import { userHistoryConstants } from '../_constants'

export function userHistory(state = {}, action) {
    const { newDataSubmission, userHistory } = action
    const previousState = state.results

    switch (action.type) {
        case userHistoryConstants.CLEAR:
            return {
                results: []
            }

        case userHistoryConstants.FETCH_COMPLETE:
            const newStateAfterFetch = previousState.concat(userHistory)
            return {
                results: newStateAfterFetch
            }


        case userHistoryConstants.ADD_NEW_SUBMISSION:
            const newStateAfterSubmission = [newDataSubmission].concat(previousState)
            return {
                results: newStateAfterSubmission
            }

        default:
            return state
    }
}