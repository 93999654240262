export const ROUTES = {
  HOME: '/',
  ABOUT: '/about',
  BENCHMARKS: '/benchmarks',
  USER_HISTORY: '/user-history',
  CONTENT_HOME: '/content',
  CONTENT_PIECE: '/content/:contentName',
  FILTER: '/filter',
  FULLINHOME: 'https://fullinpartners.com',
  RESULTS: '/results',
  UNAUTHORIZED: '/unauthorized',
}

export const CONTENT_PIECE_ROUTE = (contentName) => `/content/${contentName}`