import React from 'react'
import ReactGA from 'react-ga'
import html2canvas from 'html2canvas'
import { Button, Grid, Header, List, Dimmer, Loader, Segment, Icon, Container } from "semantic-ui-react"
import { compose } from 'recompose'

import { userHistoryActions } from '../_actions'
import { TitledPage, ContentTitle, ContentSubtitle, ButtonFromDownArrow, CompanyFilter, PageView } from '../_components'
import { ComparisonChart, UnicornMetricChart } from '../_components/charts'
import { trackableEvents, colorConstants, ROUTES } from '../_constants'
import { RestApi, MetricsRanker } from '../_services'
import { history, connectToUserSession, connectToCurrentFilters, connectToCompanyData } from '../_helpers'

import './ResultsPage.css'

class UnconnectedResultsPage extends React.Component {
  constructor(props) {
    super(props)

    this.initialAuthUid = props.auth.uid
    this.userProvidedData = this.getAllUserProvidedData()
    this.chartRef = React.createRef()

    this.state = {
      emailDialogueOpen: false
    }
  }

  componentDidMount() { PageView() }

  getUrlVars() {
    let str = window.location.search.substr(3)
    str = "?" + atob(decodeURIComponent(str))
    let vars = {};
    str.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  getAllUserProvidedData() {
    const params = this.getUrlVars()
    return {
      year_founded: params["yearFounded"],
      revenue: params["revenue"],
      yoy_growth: params["yoyGrowth"],
      cap_raised: params["capRaisedData"],
      num_employees: params["empCount"],
      burn_rate: params["burn"]
    }
  }

  onUserLogin = () => {
    const { auth, dispatch } = this.props
    const restApi = new RestApi(auth)
    restApi.saveUserData(this.userProvidedData)
      .then(() => {
        dispatch(userHistoryActions.fetchHistory(auth.uid))

        ReactGA.event({
          category: trackableEvents.LOGIN,
          action: `Login from results page`,
        })
      })
  }

  sendDataAndEmailPostRequest = (dataUrl, linkTarget) => {
    const restApi = new RestApi(this.props.auth)
    restApi.saveUserData(this.userProvidedData)
    restApi.makeEmailRequest(dataUrl, linkTarget)
  }

  openEmailDialogue = () => {
    this.setState({ emailDialogueOpen: true })
  }

  closeEmailDialogue = () => {
    this.setState({ emailDialogueOpen: false })
  }

  handleEmailRequest = (event) => {
    event.preventDefault()

    // Got to be a better way??? This is nonsense, trial & error to get magic numbers
    const windowSizeOptions = {
      width: 1000,
      height: 540,
      x: 245,
      y: 265,
      windowWidth: 1600,
      windowHeight: 1200,
      scrollX: 0,
      scrollY: 0,
    }

    html2canvas(document.getElementById('comparison-graphics-block'), {
      allowTaint: false,
      ...windowSizeOptions
    })
      .then(canvas => {
        const dataUrl = canvas.toDataURL()
        this.sendDataAndEmailPostRequest(dataUrl, canvas.baseURI)

        ReactGA.event({
          category: trackableEvents.REQUEST_EMAIL,
          action: `Data email requested`,
        })

        this.openEmailDialogue()
      })
      .catch(err => console.log(err))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.isEmpty && !this.props.auth.isEmpty) {
      this.onUserLogin()
    }
  }

  render() {
    const { currentFilters, companyData } = this.props
    const metricsRanker = new MetricsRanker(this.userProvidedData, companyData, currentFilters)
    const percentileRanks = metricsRanker.getPercentileRanks()
    const overallRank = metricsRanker.getOverallRank()
    const dimmerActive = !overallRank

    return (
      <TitledPage
        darkPage={false}
        title="YOUR HEALTH CHECK"
        subtitles={["Congratulations on taking the first step towards long-term success! This health check score is meant to give you a fuller picture of your company's strengths and weaknesses based on several key indicators."]}
        subtitleWidth="75%"
      >
        <div>
          <Dimmer.Dimmable as={Segment} blurring dimmed={dimmerActive}>
            <Dimmer active={dimmerActive}>
              <Loader>Loading</Loader>
            </Dimmer>
            <Container className="results-block" style={{ backgroundColor: colorConstants.Toolbox, width: '100%' }}>
              <Grid columns='two' stackable reversed='mobile' relaxed>
                <Grid.Column style={{ backgroundColor: colorConstants.Toolbox, color: colorConstants.White }} width={6}>
                  <div id='unicorn-metric-block'>
                    <UnicornMetricChart percentileRank={overallRank} />
                    <UnicornMetricInfo percentileRank={overallRank} userProvidedData={this.userProvidedData} />
                    <SocialMediaSharing />
                  </div>
                </Grid.Column>
                <Grid.Column className="comparison-chart-column" floated='left' width={10} style={{ backgroundColor: colorConstants.Toolbox }} only={'tablet computer'}>
                  <ComparisonChart percentileRanks={percentileRanks} />
                </Grid.Column>
                <Grid.Column className="comparison-chart-column" style={{ backgroundColor: colorConstants.Toolbox }} only={'mobile'}>
                  <div className="mobile-chart-column">
                    <ComparisonChart percentileRanks={percentileRanks} />
                  </div>
                </Grid.Column>
              </Grid>
              <FilterSection />
            </Container>
          </Dimmer.Dimmable>
        </div>
        <ButtonFromDownArrow
          content="LEARN ABOUT YOUR SCORE"
          darkButton={false}
          buttonCentered={true}
          onClick={() => history.push(ROUTES.BENCHMARKS)}
        />
      </TitledPage>
    )
  }
}

class FilterSection extends React.Component {
  constructor() {
    super()
    this.state = {
      expanded: true
    }
  }

  toggleExpandedState = () => {
    const { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  render() {
    const { expanded } = this.state
    const buttonIcon = expanded ? 'minus' : 'plus'

    return (
      <div className="results-company-filter" style={{ backgroundColor: colorConstants.Toolbox }}>
        <div className="benchmarks-section-header">
          <Button icon={true} inverted={true} onClick={this.toggleExpandedState} >
            <Icon name={buttonIcon} size='large' />
          </Button>
          <ContentTitle content='APPLY FILTERS' />
        </div>
        <div>
          {expanded ? <CompanyFilter /> : undefined}
        </div>
      </div>
    )
  }
}

export const ResultsPage = compose(
  connectToCompanyData,
  connectToUserSession,
  connectToCurrentFilters)(UnconnectedResultsPage)

// function EmailDialogueWindow(props) {
//   return (
//     <Modal
//       onClose={props.handleClose}
//       open={props.isOpen}
//       size='mini'
//     >
//       <div className="email-dialogue">
//         <Image className='modal-logo' centered src='/assets/images/logo_purple.png' size='tiny' />
//         <Header
//           size='large'
//           textAlign="center"
//           content="Your results have been sent!"
//         />
//         <Header
//           size='large'
//           textAlign="center"
//           content="Come back soon!"
//         />

//         <a href="https://fullinpartners.com">
//           <Header size='large' textAlign="center">
//             <Icon
//               name='heart outline'
//               color='pink'
//               size='huge'
//             />
//             - The Full In Team
//         </Header>
//         </a>
//       </div>
//     </Modal>
//   )
// }

// function UnconnectedResultsCtaButton(props) {
//   return (
//     <div>
//       {hasAuthenticatedUser(props.auth) ?
//         <EmailButton handleEmailRequest={props.handleEmailRequest} /> :
//         <LoginButton />}
//     </div>
//   )
// }

// const ResultsCtaButton = connectToUserSession(UnconnectedResultsCtaButton)

// function EmailButton(props) {
//   return (
//     <Button
//       content='EMAIL ME MY RESULTS'
//       id="email-button"
//       className="button-with-text purple-button"
//       onClick={props.handleEmailRequest}
//     />
//   )
// }

// function UnconnectedLoginButton(props) {
//   return (
//     <div className='results-login-wrapper'>
//       {props.auth.isEmpty ? <Header size='small' content="Log in to save your results, view personalized graphs, and more..." /> : null}
//       <LoginWrapper openableTrigger={(handleOpen) => (
//         <Button
//           content='WANT MORE?'
//           id="results-login-button"
//           className="button-with-text purple-button"
//           onClick={handleOpen} />
//       )} />
//     </div>
//   )
// }

// const LoginButton = connectToUserSession(UnconnectedLoginButton)

const numberWithCommas = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

function UnicornMetricInfo({ percentileRank, userProvidedData }) {
  const percentileRankDefined = percentileRank && percentileRank !== 0
  const percentileRankLabel = String(percentileRank)
  const percentPrefix = percentileRankLabel.startsWith('8') ? 'AN' : 'A'
  const label = percentileRankDefined ? `YOU SCORED ${percentPrefix} ${percentileRankLabel}%` : ''

  return (
    <div className="unicorn-metric-chart-label">
      <ContentTitle content={label} />
      <Grid columns={2}>
        <Grid.Column>
          <List inverted >
            <List.Item>
              <ContentSubtitle content='Founded:' size='small' />
              {userProvidedData.year_founded}
            </List.Item>
            <List.Item>
              <ContentSubtitle content='Revenue:' size='small' />
              ${numberWithCommas(userProvidedData.revenue)}
            </List.Item>
            <List.Item>
              <ContentSubtitle content='YoY Growth:' size='small' />
              {numberWithCommas(userProvidedData.yoy_growth)}%
              </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column>
          <List inverted>
            <List.Item>
              <ContentSubtitle content='Total Raised:' size='small' />
              ${numberWithCommas(userProvidedData.cap_raised)}
            </List.Item>
            <List.Item>
              <ContentSubtitle content='Employees:' size='small' />
              {numberWithCommas(userProvidedData.num_employees)}
            </List.Item>
            <List.Item>
              <ContentSubtitle content='Monthly Burn:' size='small' />
              ${numberWithCommas(userProvidedData.burn_rate)}
            </List.Item>
          </List>
        </Grid.Column>
      </Grid>
      <hr />
    </div>
  )
}

function SocialMediaSharing() {
  return (
    <div id="social-sharing">
      <Header content='Share with friends' textAlign='center' inverted />
      <div id="social-sharing-links">
        <span>
          <a className="social-link f" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ffullinpartners.com%2Fmetrics" target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/white-facebook-logo.png" alt="Share to Facebook" />
          </a>
          <a className="social-link t" href="https://twitter.com/home?status=Try%20our%20Free%20Benchmark%20Tool%20%20Take%20a%20minute%20to%20see%20how%20you%20stack%20up%20against%20the%20industry%0Ahttps%3A%2F%2Ffullinpartners.com%2Fmetrics" target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/white-twitter-logo.png" alt="Share to Twitter" />
          </a>
          <a className="social-link li" href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Ffullinpartners.com%2Fmetrics&title=Try%20our%20Free%20Benchmark%20Tool&summary=Take%20a%20minute%20to%20see%20how%20you%20stack%20up%20against%20the%20industry&source=" target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/white-linkedin-logo.jpg" alt="Share to LinkedIn" />
          </a>
        </span>
      </div>
    </div>
  )
}

