import { userHistoryConstants } from '../_constants'
import { RestApi } from '../_services'

export const userHistoryActions = {
    fetchHistory,
    clearHistory,
    addNewSubmission
}

function fetchHistory(uid) {
    return dispatch => {
        dispatch(request())

        const restApi = new RestApi()
        restApi.getHistoricalData(uid)
            .then(resultHistory => {
                dispatch(success(resultHistory))
            })
            .catch(error => {
                dispatch(failure(error.toString()))
            })
    }

    function request() { return { type: userHistoryConstants.FETCH_STARTED } }
    function success(userHistory) { return { type: userHistoryConstants.FETCH_COMPLETE, userHistory } }
    function failure(error) { return { type: userHistoryConstants.FETCH_FAILURE, error } }
}

function addNewSubmission(newDataSubmission) {
    return dispatch => {
        dispatch(success(newDataSubmission))

        function success(newDataSubmission) { return { type: userHistoryConstants.ADD_NEW_SUBMISSION, newDataSubmission } }
    }
}

function clearHistory() {
    return dispatch => dispatch(clear())

    function clear() { return { type: userHistoryConstants.CLEAR } }
}
