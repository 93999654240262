import { createBrowserHistory } from 'history'

const _initializeHistory = () => {
    const _history = createBrowserHistory()
    return _history
}

export const history = _initializeHistory()

export const directToExternalSite = (url) => {
    window.location.href = url
    return null
}