import React from 'react'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { Modal, Header, Button, Icon, Image } from 'semantic-ui-react'

import { ROUTES, trackableEvents } from '../../_constants'
import { userHistoryActions } from '../../_actions'
import { history, connectToFirebaseAuth } from '../../_helpers'

class UnconnectedLogoutWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.openableTrigger = props.openableTrigger
    this.state = {
      modalOpen: false
    }
  }

  handleOpen = () => {
    this.setState({ modalOpen: true })
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  onSuccessfulLogout = () => {
    const { clearUserHistory } = this.props

    clearUserHistory()
    ReactGA.event({
      category: trackableEvents.LOGOUT,
      action: `Successful logout`,
    })

    history.push(ROUTES.HOME)
  }

  render() {
    return (
      <Modal
        trigger={this.openableTrigger(this.handleOpen)}
        centered={false}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size='small'
      >
        <div className='modal-login-container'>
          <Image className='modal-logo' centered src='/assets/images/logo_purple.png' size='tiny' />
          <LogoutWindow
            handleClose={this.handleClose}
            onSuccessfulLogout={this.onSuccessfulLogout}
          />
        </div>
      </Modal>
    )
  }
}

const mapDispatchToProps = {
  clearUserHistory: userHistoryActions.clearHistory
}

export const LogoutWrapper = connect(null, mapDispatchToProps)(UnconnectedLogoutWrapper)

class UnconnectedLogoutWindow extends React.Component {
  handleLogoutSubmit = (event) => {
    event.preventDefault()

    const { firebase, onSuccessfulLogout } = this.props
    firebase.logout()
      .then(() => onSuccessfulLogout())
  }

  render() {
    return (
      <div className='logout-window partial-width'>
        <Header className='login-header' textAlign='center' size='medium'>Log Out</Header>
        <div className='logout-text'>
          <p>Are you sure you want to log out?</p>
        </div>

        <div className='logout-cta'>
          <Button color='red' floated='left' onClick={this.props.handleClose}>
            <Icon name='cancel' /> Cancel
          </Button>
          <Button floated='right' onClick={this.handleLogoutSubmit}>
            <Icon name='check' /> Yes
          </Button>
        </div>
      </div>
    )
  }
}

const LogoutWindow = connectToFirebaseAuth(UnconnectedLogoutWindow)