import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import { config } from '../_config'
import { store } from '../_helpers'

const firebaseStoreConfig = {
  userProfile: 'users',
  presence: 'presence',
  sessions: 'sessions'
}

export const getFirebaseProps = () => {
  firebase.initializeApp(config.firebase)

  return {
    firebase,
    config: firebaseStoreConfig,
    dispatch: store.dispatch
  }
}