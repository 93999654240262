import { currentFiltersConstants } from '../_constants'

const INITIAL_STATE = {
    sector: -1,
    age: -1,
    valuation: -1,
    capRaised: -1
}

export function currentFilters(state = {}, action) {
    switch (action.type) {
        case currentFiltersConstants.SET_FILTER:
            const { filterName, filterValue } = action
            return {
                ...state,
                [filterName]: filterValue
            }

        case currentFiltersConstants.RESET:
            return INITIAL_STATE

        default:
            return state
    }
}