import React from 'react'

import { PlotlyWrapper } from './plotlyWrapper'

import { isValidNum, axisLabelTitle, generateScatterAndTrendTraces, dashboardChartWrapper } from './chartUtilities'


export function UnconnectedRevenuePerAgeChart(props) {
  const { companyData, lastUserInput } = props
  const filteredData = companyData.filter(row => isValidNum(row.revenue) && isValidNum(row.age))
  const revenuePoints = filteredData.map(row => +row.revenue)
  const agePoints = filteredData.map(row => +row.age)
  const { scatterTrace, trendLineTrace } = generateScatterAndTrendTraces(agePoints, revenuePoints)

  const lastUserDataTrace = lastUserInput ? {
    x: [(new Date().getFullYear() - lastUserInput.year_founded)],
    y: [lastUserInput.revenue],
    type: 'scatter',
    marker: {
      size: 10,
      color: 'red'
    },
    hoverinfo: 'text',
    text: `Your Data: $${lastUserInput.revenue / 1000000}M`,
  } : {}

  const chartDescription = {
    layout: {
      xaxis: {
        title: axisLabelTitle('Years since Founding'),
        range: [0, 15.5]
      },
      yaxis: {
        title: axisLabelTitle('Annual Revenue')
      },
      height: 350,
      showlegend: false,
      margin: {
        l: 60,
        r: 40,
        b: 40,
        t: 20,
      }
    },
    data: [scatterTrace, trendLineTrace, lastUserDataTrace]
  }

  return (
    <PlotlyWrapper chartDescription={chartDescription} />
  )
}

const RevenuePerAgeChart = dashboardChartWrapper(UnconnectedRevenuePerAgeChart)
export { RevenuePerAgeChart }
