import React from 'react'
import { Header } from 'semantic-ui-react'
import { isMobile } from "react-device-detect"

import { colorConstants } from '../_constants'

const pageTitleStyle = {
    textAlign: 'left',
    fontFamily: "Arial Black, Gadget, sans-serif",
    lineHeight: '1em'
}

const pageSubtitleStyle = {
    fontSize: 16,
    textAlign: 'left',
    fontFamily: "Trebuchet MS, Helvetica, sans-serif",
    lineHeight: '1.35em',
}

const contentTitleStyle = {
    textAlign: 'left',
    fontFamily: "Arial Black, Gadget, sans-serif",
    lineHeight: '1em'
}

const contentSubtitleStyle = {
    fontSize: 14,
    textAlign: 'left',
    fontFamily: "Trebuchet MS, Helvetica, sans-serif",
    lineHeight: '1em',
}


export function PageTitle({ id, content, darkText }) {
    const color = darkText ? colorConstants.PurpleTaupe : colorConstants.White
    const fontSize = isMobile ? 40 : 60
    const style = { ...pageTitleStyle, color, fontSize }

    return (
        <Header
            id={id}
            style={style}
            content={content}
        />
    )
}

export function PageSubtitle({ content, darkText, width }) {
    const color = darkText ? colorConstants.EerieBlack : colorConstants.White
    const sectionWidth = width ? width : '90%'
    const style = { ...pageSubtitleStyle, color, maxWidth: sectionWidth }

    return (
        <Header
            style={style}
            content={content}
        />
    )
}

export function ContentTitle({ content, darkText, floated }) {
    const color = darkText ? colorConstants.PurpleTaupe : colorConstants.White
    const fontSize = isMobile ? 28 : 36
    const style = { ...contentTitleStyle, color, fontSize }

    return (
        <Header
            style={style}
            content={content}
            floated={floated}
        />
    )
}

export function ContentSubtitle({ content, darkText, children }) {
    const color = darkText ? colorConstants.EerieBlack : colorConstants.White
    const style = { ...contentSubtitleStyle, color }
    if (content) {
        return (
            <Header
                style={style}
                content={content}
            />
        )
    }

    return (
        <Header style={style}>{children}</Header>
    )
}