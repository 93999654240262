import React from 'react'

import { config } from '../_config'
import { DataFetcher } from '../_services'

export const CompanyDataContext = React.createContext(null)

const dataFetcher = new DataFetcher(config.dataFileLocation)

// Not putting in react store because its making store slow
export class CompanyDataProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      companyData: []
    }
  }

  componentDidMount() {
    const dataResultsCallback = (dataResults) => {
      this.setState({ companyData: dataResults })
    }

    const errorCallback = (error) => {
      console.error("Unable to load company data: " + error)
    }

    if (this.state.companyData.length === 0) {
      dataFetcher.fetch(dataResultsCallback, errorCallback)
    }
  }

  render() {
    return (
      <CompanyDataContext.Provider value={this.state.companyData}>
        {this.props.children}
      </CompanyDataContext.Provider>
    )
  }
}

export const connectToCompanyData = Component => {
  function WithCompanyData(props) {
    return (
      <CompanyDataContext.Consumer>
        {companyData => (<Component {...props} companyData={companyData} />)}
      </CompanyDataContext.Consumer>
    )
  }

  return WithCompanyData
}