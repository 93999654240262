import React from 'react'

import { PlotlyWrapper } from './plotlyWrapper'

import { isValidNum, CHART_COLORS, axisLabelTitle, dashboardChartWrapper } from './chartUtilities'

const XRANGE_MAX = 1200000

export const getXRangeBasedOnRevPerEmployee = (lastUserInput) => {
  const numTicks = 100
  const lastUserRevPerEmployee = lastUserInput ? +lastUserInput.revenue / +lastUserInput.num_employees : 0
  const maxRange = Math.max(XRANGE_MAX, lastUserRevPerEmployee + 100000)
  const tickSpace = maxRange / numTicks

  let ticks = []
  for (let i = 0; i < numTicks; i++) {
    ticks[i] = tickSpace * i
  }

  return ticks
}

export const getPercentOfCompaniesAboveThreshold = (companyRevenuePerEmployee, threshold) => {
  const totalCompanies = companyRevenuePerEmployee.length
  const companiesAboveThreshold = companyRevenuePerEmployee.filter(item => item > threshold).length
  const percent = (companiesAboveThreshold / totalCompanies) * 100
  return percent
}

export function UnconnectedRevenuePerEmployeeChart(props) {
  const { companyData, lastUserInput } = props
  const filteredData = companyData.filter(row => isValidNum(row.revenue) && isValidNum(row.numEmployees))
  const companyRevenuePerEmployee = filteredData.map(row => row.revenue / row.numEmployees)

  const xTicks = getXRangeBasedOnRevPerEmployee(lastUserInput)
  let companiesAboveThreshold = []
  for (let i = 0; i < xTicks.length; i++) {
    const threshold = xTicks[i]
    companiesAboveThreshold[i] = getPercentOfCompaniesAboveThreshold(companyRevenuePerEmployee, threshold)
  }

  const trace = {
    x: xTicks,
    y: companiesAboveThreshold,
    fill: 'tozeroy',
    type: 'scatter',
    line: { color: CHART_COLORS.PurpleHeart }
  }

  const shapes = lastUserInput ? [{
    type: 'line',
    x0: (lastUserInput.revenue / lastUserInput.num_employees),
    y0: 0,
    x1: (lastUserInput.revenue / lastUserInput.num_employees),
    yref: 'paper',
    y1: 1,
    hoverinfo: 'text',
    text: 'YOUR DATA',
    line: {
      color: 'red',
      width: 1.5,
    }
  }] : []

  const chartDescription = {
    layout: {
      xaxis: {
        title: axisLabelTitle('Revenue per Employee'),
        tickmode: 'linear',
        tick0: 0,
        dtick: 200000,
        range: [0, XRANGE_MAX]
      },
      yaxis: { title: axisLabelTitle('% of Companies Exceeding Ratio') },
      height: 350,
      shapes,
      margin: {
        l: 50,
        r: 40,
        b: 40,
        t: 20,
        pad: 1
      },
    },
    data: [trace]
  }

  return (
    <PlotlyWrapper chartDescription={chartDescription} />
  )
}

export const RevenuePerEmployeeChart = dashboardChartWrapper(UnconnectedRevenuePerEmployeeChart)