import React from 'react'
import ReactGA from 'react-ga'
import { Form, Message, Button } from 'semantic-ui-react'

import { connectToFirebaseAuth } from '../../_helpers'
import { trackableEvents } from '../../_constants'

const INITIAL_STATE = {
  email: '',
  password: '',
  submitted: false,
  error: null,
}

class UnconnectedManualUserPasswordForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = INITIAL_STATE
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
      submitted: false
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()

    const { email, password } = this.state
    const { firebase, onSuccessfulLogin } = this.props
    this.setState({ submitted: true })

    firebase.login({ email, password })
      .then(response => {
        onSuccessfulLogin(response)
        ReactGA.event({
          category: trackableEvents.LOGIN,
          action: `Successful manual login`,
        })
      })
      .catch(error => this.setState({ error }))
  }

  render() {
    const { email, password, submitted, error } = this.state
    const { toggleToSignUp } = this.props
    const validForm = email !== '' && password !== ''

    return (
      <div className='user-login-form partial-width'>
        {submitted && error && <Message error header='Login Failed' content={error ? error.message : ''} />}
        <Form onSubmit={this.handleSubmit} >
          <Form.Field>
            <Form.Input label='Email' name="email" value={email} onChange={this.handleChange} placeholder='Email Address' />
          </Form.Field>
          <Form.Field>
            <Form.Input type='password' label='Password' name="password" value={password} onChange={this.handleChange} placeholder='Password' />
          </Form.Field>
          <Button primary floated='right' disabled={!validForm}>Sign In</Button>
        </Form>
        <Button onClick={toggleToSignUp} floated='left'>Sign Up</Button>
      </div>
    )
  }
}

const ManualUserPasswordForm = connectToFirebaseAuth(UnconnectedManualUserPasswordForm)

export { ManualUserPasswordForm }