import React from 'react'

import { PlotlyWrapper } from './plotlyWrapper'

import { isValidNum, generateHistogramWithDistributionTraces, axisLabelTitle, dashboardChartWrapper } from './chartUtilities'

export function UnconnectedYoYGrowthChart(props) {
  const { companyData, lastUserInput } = props
  const filteredData = companyData.filter(row => isValidNum(row.yoyGrowth))
  const yoyGrowthPoints = filteredData.map(row => +row.yoyGrowth)
  const xStart = -26
  const xEnd = Math.max(130, lastUserInput ? +lastUserInput.yoy_growth + 10 : 0)
  const bins = {
    start: xStart,
    end: xEnd,
    size: 5
  }

  const { histogramTrace, normalDistTrace } = generateHistogramWithDistributionTraces(yoyGrowthPoints, bins)

  const shapes = lastUserInput ? [{
    type: 'line',
    x0: lastUserInput.yoy_growth,
    y0: 0,
    x1: lastUserInput.yoy_growth,
    yref: 'paper',
    y1: 1,
    line: {
      color: 'red',
      width: 2,
    }
  }] : []


  const chartDescription = {
    layout: {
      xaxis: {
        title: axisLabelTitle('YoY Growth %'),
        range: [xStart, xEnd],
        tickmode: 'linear',
        tick0: -50,
        dtick: 25,
      },
      showlegend: false,
      yaxis: { title: axisLabelTitle('% of Companies') },
      bargap: 0.3,
      height: 350,
      shapes,
      margin: {
        l: 40,
        r: 20,
        b: 40,
        t: 20,
      }
    },
    data: [histogramTrace, normalDistTrace]
  }

  return (
    <PlotlyWrapper chartDescription={chartDescription} />
  )
}

export const YoYGrowthChart = dashboardChartWrapper(UnconnectedYoYGrowthChart)
