import React from 'react'

import { PlotlyWrapper } from './plotlyWrapper'

import { generateScatterAndTrendTraces, isValidNum, axisLabelTitle, dashboardChartWrapper } from './chartUtilities'

export function UnconnectedRevenuePerCapitalChart(props) {
  const { companyData, lastUserInput } = props
  const filteredData = companyData
    .filter(row => isValidNum(row.revenue) && isValidNum(row.totalRaised))

  const totalRaisedPoints = filteredData.map(row => +row.totalRaised)
  const revenuePoints = filteredData.map(row => +row.revenue)
  const { scatterTrace, trendLineTrace } = generateScatterAndTrendTraces(totalRaisedPoints, revenuePoints)

  const lastUserDataTrace = lastUserInput ? {
    x: [lastUserInput.cap_raised],
    y: [lastUserInput.revenue],
    type: 'scatter',
    marker: {
      size: 10,
      color: 'red'
    },
    hoverinfo: 'text',
    text: `Your Data: $${lastUserInput.revenue / 1000000}M`,
  } : {}

  const chartDescription = {
    layout: {
      xaxis: { title: axisLabelTitle('Capital Raised') },
      yaxis: { title: axisLabelTitle('Annual Revenue') },
      height: 350,
      showlegend: false,
      margin: {
        l: 60,
        r: 40,
        b: 40,
        t: 20,
      }
    },
    data: [scatterTrace, trendLineTrace, lastUserDataTrace]
  }

  return (
    <PlotlyWrapper chartDescription={chartDescription} />
  )
}

const RevenuePerCapitalChart = dashboardChartWrapper(UnconnectedRevenuePerCapitalChart)

export { RevenuePerCapitalChart }

