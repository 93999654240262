import React from 'react'
import PropTypes from 'prop-types'
import { Container, Button, Icon, Grid } from 'semantic-ui-react'

import { CONTENT_PIECE_ROUTE, CONTENT_PIECES } from '../_constants'
import { TitledPage, ContentTitle, ContentSubtitle, PageView } from '../_components'
import {
  RevenuePerAgeChart,
  CapitalEfficiencyChart,
  YoYGrowthChart,
  RevenuePerCapitalChart,
  RevenuePerEmployeeChart,
  GrowthRateForRevenueChart
} from '../_components/charts'

import './BenchmarksPage.css'

export class BenchmarksPage extends React.Component {
  componentDidMount() { PageView() }

  render() {
    return (
      <TitledPage
        title='BENCHMARKS'
        subtitles={[
          "We've compiled data on over 280 thousand companies from over 12 data sources in order to develop the only comprehensive suite of startup benchmarks.",
          "Dig into the categories below to understand why we focus on these metrics, how we calculate your score, and how to improve your both your score and your business.",
        ]}
        subtitleWidth="60%"
        darkPage={false}
      >
        <BenchmarkSection
          sectionTitle="REVENUE OVER TIME"
          contentTitle="REVENUE FOR AGE"
          contentText={[
            "This chart depicts annual recurring revenues for a number of companies relative to their age.",
            "Although there are many underlying drivers of revenue growth at this stage of your company’s lifecycle, this metric is one of the most visible indicators of product market fit and successful execution.",
            "With the advent of cloud computing and the SaaS business model, the markets are more competitive than ever. So, if you’re not moving fast, its possible you’re hindering your opportunity for long-term growth and missing out on the premium placed on high-momentum businesses."
          ]}
          contentLink={CONTENT_PIECE_ROUTE(CONTENT_PIECES.REVENUE_PER_AGE)}
          chartName="RevenuePerAgeChart"
        />
        <BenchmarkSection
          sectionTitle="GROWTH"
          contentTitle="YEAR OVER YEAR GROWTH"
          contentText={[
            "This chart shows the distribution of all annual growth rates for technology startups in the last ten years.",
            "As you can see, the median hovers just between 40% and 50% and companies over 100% growth are the rare exception in the startup landscape, contrary to popular belief.",
            "Growth is usually a good proxy for product-market fit. When growth is high and fueled responsibly, it generally means that your company is addressing a pressing need in the market, that the market is large and/or growing, and that you may be benefiting from a larger macro effect. When growth is low, you may need to examine one of those key factors"
          ]}
          contentLink={CONTENT_PIECE_ROUTE(CONTENT_PIECES.GROWTH_RATE)}
          chartName="YoYGrowthChart"
        />
        <BenchmarkSection
          sectionTitle="CAPITAL RAISED"
          contentTitle="REVENUE PER CAPITAL RAISED"
          contentText={[
            "This chart depicts annual recurring revenues for a number of companies relative to the amount of capital they have raised to date.",
            "If you have repeatedly raised capital with low growth, it could mean that you are building a complex product or are pursuing a network-effect business model. However, it may also indicate you have failed to address a customer need in the market which will inevitably lead to higher customer acquisition costs and poor customer retention.",
            "On the other hand, if you have managed to generate high revenues with little capital, this may be an opportunity to reduce your personal risk in the business. This has the additional benefit of encouraging you to continue taking risks in order to grow the business."
          ]}
          contentLink={CONTENT_PIECE_ROUTE(CONTENT_PIECES.REVENUE_PER_CAPITAL)}
          chartName="RevenuePerCapitalChart"
        />
        <BenchmarkSection
          sectionTitle="CAPITAL EFFICIENCY"
          contentTitle="CAPITAL EFFICIENCY"
          contentText={[
            "This chart shows the ranges of capital efficiency for companies at various stages of their lifecycle.",
            "In order to calculate capital efficiency, we developed a formula taking revenue, growth, and burn rate into account. To put it simply, this calculation shows how fast new business is growing relative to costs to acquire that new business.",
            "This metric should matter to you because it should indicate how profitable your business can potentially be in the long run. In a world where capital is available, companies can afford to continue burning through capital. But in a recession or downturn, it’s important to know how much runway you’ll have left and how efficiently you can acquire new business."
          ]}
          contentLink={CONTENT_PIECE_ROUTE(CONTENT_PIECES.CAPITAL_EFFICIENCY)}
          chartName="CapitalEfficiencyChart"
        />
        <BenchmarkSection
          sectionTitle="EMPLOYEE EFFICIENCY"
          contentTitle="REVENUE PER EMPLOYEE"
          contentText={[
            "This chart shows employee efficiency highlighting by the percentage of companies which exceed a given revenue per employee number. We believe this metric should be important to you because it may highlight areas of your company that need additional investment.",
            "If your revenue per employee is low, it could have several different meanings. It could indicate that your back office needs process automation to handle a greater volume of invoices and other tasks. If growth is high, it could mean that your team cannot efficiently support new customers.  Or it could indicate that you have under-invested in customer success and support, resulting in a continuous tax of small fixes rather than larger product improvements.",
            "If revenue per employee is exceptionally high, it likely means that your team is heavy on product and you could consider investing in a larger sales and marketing team."
          ]}
          contentLink={CONTENT_PIECE_ROUTE(CONTENT_PIECES.REVENUE_PER_EMPLOYEE)}
          chartName="RevenuePerEmployeeChart"
        />
      </TitledPage>
    )
  }
}

const chartNameMap = {
  "RevenuePerAgeChart": (<RevenuePerAgeChart />),
  "RevenuePerEmployeeChart": (<RevenuePerEmployeeChart />),
  "GrowthRateForRevenueChart": (<GrowthRateForRevenueChart />),
  "RevenuePerCapitalChart": (<RevenuePerCapitalChart />),
  "CapitalEfficiencyChart": (<CapitalEfficiencyChart />),
  "YoYGrowthChart": (<YoYGrowthChart />)
}

class BenchmarkSection extends React.Component {
  constructor() {
    super()
    this.state = {
      expanded: false
    }
  }

  toggleExpandedState = () => {
    const { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  render() {
    const { sectionTitle, contentTitle, contentText, contentLink, chartName } = this.props
    const { expanded } = this.state
    const buttonIcon = expanded ? 'minus' : 'plus'
    const chart = chartNameMap[chartName]

    return (
      <div>
        <div className="benchmarks-section-header">
          <Button
            icon={true}
            inverted={true}
            onClick={this.toggleExpandedState}
          >
            <Icon name={buttonIcon} size='big' />
          </Button>
          <ContentTitle content={sectionTitle} />
        </div>
        <div>
          {
            expanded ? <ContentSection
              chart={chart}
              contentTitle={contentTitle}
              contentText={contentText}
              contentLink={contentLink}
            /> : undefined
          }
        </div>
      </div>
    )
  }
}

const ContentSection = ({ contentTitle, contentText, contentLink, chart }) => {
  const contentTextComponents = contentText.map(text => (
    <ContentSubtitle darkText={true} content={text} />
  ))

  return (
    <Container className="benchmarks-section-content">
      <Grid stackable>
        <Grid.Column width={8} floated='left' >
          {chart}
        </Grid.Column>
        <Grid.Column width={8}>
          <div className="benchmarks-section-text">
            <ContentTitle darkText={true} content={contentTitle} />
            {contentTextComponents}
            <ContentSubtitle darkText={true}>
              <a href={contentLink}>Click HERE to learn more about this metric, why it matters, and what to do about it</a>
            </ContentSubtitle>
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

BenchmarksPage.propTypes = {
  mobile: PropTypes.bool,
}