import React from 'react'
import MarkdownRenderer from 'react-markdown-renderer'
import { Container, Grid, Dimmer, Segment, Loader } from 'semantic-ui-react'
import { compose } from 'recompose'

import { colorConstants, ROUTES, CONTENT_PIECES } from '../_constants'
import { history, connectToLastUserInput, connectToCurrentFilters, connectToCompanyData } from '../_helpers'
import { MetricsRanker } from '../_services'
import { ContentTitle, withAuthenticatedUser, PageSubtitle, ButtonWithDownArrow, TitledPage, ContentSubtitle, ImagePage, PageView } from '../_components'
import {
  RevenuePerAgeChart,
  CapitalEfficiencyChart,
  RevenuePerCapitalChart,
  RevenuePerEmployeeChart,
  GrowthRateForRevenueChart,
  UnicornMetricChart
} from '../_components/charts'

import './contentPiecePage.css'

const chartNameMap = {
  [CONTENT_PIECES.REVENUE_PER_AGE]: (<RevenuePerAgeChart />),
  [CONTENT_PIECES.REVENUE_PER_EMPLOYEE]: (<RevenuePerEmployeeChart />),
  [CONTENT_PIECES.GROWTH_RATE]: (<GrowthRateForRevenueChart />),
  [CONTENT_PIECES.REVENUE_PER_CAPITAL]: (<RevenuePerCapitalChart />),
  [CONTENT_PIECES.CAPITAL_EFFICIENCY]: (<CapitalEfficiencyChart />)
}

const contentTitleMap = {
  [CONTENT_PIECES.REVENUE_PER_AGE]: "REVENUE per AGE",
  [CONTENT_PIECES.REVENUE_PER_EMPLOYEE]: "REVENUE per EMPLOYEE",
  [CONTENT_PIECES.GROWTH_RATE]: "YEAR over YEAR GROWTH",
  [CONTENT_PIECES.REVENUE_PER_CAPITAL]: "REVENUE per CAPITAL RAISED",
  [CONTENT_PIECES.CAPITAL_EFFICIENCY]: "CAPITAL EFFICIENCY"
}

const percentileRankMap = {
  [CONTENT_PIECES.REVENUE_PER_AGE]: ranks => ranks.pointInTimeRevenue,
  [CONTENT_PIECES.REVENUE_PER_EMPLOYEE]: ranks => ranks.revPerEmployee,
  [CONTENT_PIECES.GROWTH_RATE]: ranks => ranks.yoyGrowth,
  [CONTENT_PIECES.REVENUE_PER_CAPITAL]: ranks => ranks.revenueWithinCapRaised,
  [CONTENT_PIECES.CAPITAL_EFFICIENCY]: ranks => ranks.capEfficiency
}


export class ContentPiecePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      markdownText: '',
      validContentPiece: true,
      contentName: ''
    }
  }

  componentDidMount() {
    PageView()
    const { params } = this.props.match

    if (params) {
      const { contentName } = params
      const textFile = `/assets/content/${contentName}.txt`
      fetch(textFile)
        .then((response) => {
          return response.text()
        })
        .then((markdownText) => {
          // TODO - better way to recognize missing content?
          const validContentPiece = !markdownText.startsWith("<!doctype html>")
          this.setState({ markdownText, validContentPiece, contentName })
        })
    }
  }

  render() {
    const { markdownText, validContentPiece, contentName } = this.state
    const pageTitle = contentTitleMap[contentName]

    return (
      <div>
        {validContentPiece ?
          <ValidContentPiecePage
            contentName={contentName}
            markdownText={markdownText}
            pageTitle={pageTitle}
          /> :
          <MissingContentPage />
        }
      </div>
    )
  }
}

export const UnconnectedValidContentPiecePage = (props) => {
  const { markdownText, contentName, pageTitle, lastUserInput, currentFilters, companyData } = props
  const contentChart = chartNameMap[contentName]

  let rankScore = undefined
  if (lastUserInput && companyData.length > 0) {
    const metricsRanker = new MetricsRanker(lastUserInput, companyData, currentFilters)
    const percentileRanks = metricsRanker.getPercentileRanks()
    rankScore = percentileRankMap[contentName](percentileRanks)
  }

  const noPreviousUserInput = companyData.length > 0 && !lastUserInput
  const dimmerActive = !rankScore && !noPreviousUserInput

  return (
    <TitledPage
      title={pageTitle}
      subtitles={[]}
      darkText={false}
    >
      <div>
        <div className='blog-chart-block'>
          <Dimmer.Dimmable as={Segment} blurring dimmed={dimmerActive}>
            <Dimmer active={dimmerActive}>
              <Loader>Loading</Loader>
            </Dimmer>
            <Container className="results-block" style={{ backgroundColor: colorConstants.Toolbox, width: '100%' }}>
              <Grid columns='two' stackable reversed='mobile' relaxed>
                <Grid.Column style={{ backgroundColor: colorConstants.Toolbox, color: colorConstants.White }} width={6}>
                  {noPreviousUserInput ? (<NoPreviousScoreText />) : (<ContentScoreInfo percentileRank={rankScore} />)}
                </Grid.Column>
                <Grid.Column className="comparison-chart-column" floated='left' width={10} style={{ backgroundColor: colorConstants.Toolbox }} only={'tablet computer'}>
                  {contentChart}
                </Grid.Column>
                <Grid.Column className="comparison-chart-column" style={{ backgroundColor: colorConstants.Toolbox }} only={'mobile'}>
                  <div className="mobile-chart-column">
                    {contentChart}
                  </div>
                </Grid.Column>
              </Grid>
            </Container>
          </Dimmer.Dimmable>
        </div>
        <div className="blog-content-block">
          <MarkdownRenderer markdown={markdownText} />
        </div>
        <div className='blog-home-button'>
          <ButtonWithDownArrow
            content="BACK TO CONTENT HOME"
            onClick={() => history.push(ROUTES.CONTENT_HOME)}
            darkButton={false}
            buttonCentered={true}
          />
        </div>
      </div>
    </TitledPage>
  )
}

const ContentScoreInfo = ({ percentileRank }) => {
  const percentileRankDefined = percentileRank && percentileRank !== 0
  const percentileRankLabel = String(percentileRank)
  const percentPrefix = percentileRankLabel.startsWith('8') ? 'AN' : 'A'
  const label = percentileRankDefined ? `YOU SCORED ${percentPrefix} ${percentileRankLabel}%` : ''

  return (
    <div id='unicorn-metric-block'>
      <UnicornMetricChart percentileRank={percentileRank} />
      <div className="unicorn-metric-chart-label">
        <ContentTitle content={label} />
        <ContentSubtitle content={"Check out what your score means, why it matters, and what to do about it below!"} />
      </div>
    </div>
  )
}

const NoPreviousScoreText = () => {
  return (
    <div className='no-score-section'>
      <ContentTitle content="GET YOUR OWN BENCHMARK FOR THIS METRIC" />
      <ContentSubtitle content={"Get started by understanding where you stand today!"} />
      <ButtonWithDownArrow
        darkButton={true}
        content="CHECK YOUR SCORES"
        onClick={() => history.push(ROUTES.FILTER)}
        centered={true}
        arrowSize="mini"
      />
    </div>
  )
}

export const MissingContentPage = () => (
  <ImagePage>
    <ContentTitle
      id="welcome-title"
      content='UNDER CONSTRUCTION'
      darkText={true}
    />
    <PageSubtitle
      content="Oops! We couldn't find that piece of content for you. We're working hard to build the best startup toolset possible, so check back soon or visit our home page to see all of our current offerings!"
      darkText={true}
    />
    <div className='filter-button'>
      <ButtonWithDownArrow
        content="VISIT CONTENT HOME"
        onClick={() => history.push(ROUTES.CONTENT_HOME)}
        darkButton={false}
        buttonCentered={false}
      />
    </div>
  </ImagePage>
)

const ValidContentPiecePage = compose(
  withAuthenticatedUser,
  connectToCompanyData,
  connectToLastUserInput,
  connectToCurrentFilters
)(UnconnectedValidContentPiecePage)