import React from 'react'
import { Container, Segment, Image, Grid } from 'semantic-ui-react'
import { BrowserView, MobileView, isMobile } from "react-device-detect"

import { PageTitle, PageSubtitle } from './headers'
import { SiteFooter } from './siteHeader'

const outerContainerStyle = {
    width: '90%',
    margin: '0 auto',
    padding: '70px 0 40px',
    left: '5%'
}

const innerContainerStyleDesktop = {
    width: '80%',
    margin: '40px auto',
    left: '10%'
}

const innerContainerStyleMobile = {
    width: '100%',
    margin: '60px 0px'
}

export function TitledPage(props) {
    const { title, subtitles, subtitleWidth, children, darkPage } = props
    const darkText = !darkPage
    const subtitleText = subtitles ? subtitles.map(text => (
        <PageSubtitle content={text} darkText={darkText} width={subtitleWidth} key={text} />
    )) : undefined

    const innerContainerStyle = isMobile ?
        innerContainerStyleMobile :
        innerContainerStyleDesktop

    return (
        <Container style={outerContainerStyle}>
            <Container id="template-page-container" fluid>
                <PageTitle
                    content={title}
                    darkText={darkText}
                />
                {subtitleText}
                <Container style={innerContainerStyle}>
                    {children}
                </Container>
            </Container>
            <SiteFooter darkText={!darkPage} />
        </Container>
    )
}

export function ImagePage(props) {
    return (
        <Segment id="image-page" vertical>
            <Container id="template-page-container" fluid>
                <ImagePageDesktopGrid children={props.children} />
                <ImagePageMobileGrid children={props.children} />
            </Container>
            <SiteFooter darkText={true} />
        </Segment>
    )
}

export function ImagePageDesktopGrid(props) {
    return (
        <BrowserView>
            <Grid stackable>
                <Grid.Column width={4} floated='left'>
                    <Image src='/assets/images/homepage_image.png' />
                </Grid.Column>
                <Grid.Column width={10}>
                    {props.children}
                </Grid.Column>
                <Grid.Column width={1}>
                </Grid.Column>
            </Grid>
        </BrowserView>
    )
}

export function ImagePageMobileGrid(props) {
    return (
        <MobileView>
            <Grid stackable>
                <Grid.Column>
                    <Image src='/assets/images/homepage_image_mobile.png' fluid centered />
                </Grid.Column>
                <Grid.Column>
                    {props.children}
                </Grid.Column>
            </Grid>
        </MobileView>
    )
}