import React from 'react'
import { Modal, Image } from 'semantic-ui-react'

import { connectToUserSession, hasAuthenticatedUser } from '../_helpers'
import { ContentTitle, ContentSubtitle, ButtonWithDownArrow } from '.'
import { LoginWrapper } from './userLogins'

const withAuthorization = condition => Component => {
  const WithAuthorization = (props) => {
    const shouldRender = condition(props.auth)

    return (
      <div>
        {shouldRender ?
          (<Component {...props} />) :
          (<UnauthorizedSection shouldRender={shouldRender}>
            <Component {...props} />
          </UnauthorizedSection>)
        }
      </div>
    )
  }

  return connectToUserSession(WithAuthorization)
}

function UnauthorizedSection({ children }) {
  return (
    <div>
      <Modal dimmer="blurring" open={true} size="small">
        <div className="unauthorized-modal-section">
          <Image className='modal-logo' centered src='/assets/images/logo_purple.png' size='tiny' />
          <ContentTitle
            content='SIGN IN TO ACCESS THIS CONTENT'
            darkText={true}
          />
          <ContentSubtitle
            content="We're working hard to build the best startup toolset possible. Access customized benchmarks, deeper insights, and historical records by signing in."
            darkText={true}
          />
          <div className='filter-button'>
            <SignInButton />
          </div>
        </div>
      </Modal>
      {children}
    </div>
  )
}

const SignInButton = () => {
  const openableButton = (handleOpen) => (
    <ButtonWithDownArrow
      content="SIGN IN"
      onClick={handleOpen}
      darkButton={false}
      buttonCentered={true}
    />
  )

  return (<LoginWrapper openableTrigger={openableButton} />)
}

const authenticatedUserCondition = auth => !!hasAuthenticatedUser(auth)

export const withAuthenticatedUser = withAuthorization(authenticatedUserCondition)