export class CapRaisedFilter {
    queryParamToCapRaisedBuckets = {
        '-1': undefined,
        '0': { min: 0, max: 1 },
        '1': { min: 0, max: 25000000 },
        '2': { min: 25000000, max: 100000000 },
        '3': { min: 100000000 }
    }

    constructor(cap_raised_filter) {
        this.capRaisedBucket = cap_raised_filter ? this.queryParamToCapRaisedBuckets[cap_raised_filter] : undefined
    }

    shouldIncludeInSet(dataEntry) {
        if (!this.capRaisedBucket || !dataEntry.raisedBucket) { return true }

        const { min, max } = this.capRaisedBucket
        const capRaised = dataEntry.totalRaised
        const moreThanMin = min ? capRaised >= min : true
        const lessThanMax = max ? capRaised < max : true
        return moreThanMin && lessThanMax
    }
}

export class UserProvidedCapRaisedFilter {
    customCapRaisedBuckets = [
        { min: 0, max: 1 },
        { min: 1, max: 10000000 },
        { min: 10000000, max: 50000000 },
        { min: 50000000 }
    ]

    constructor(userProvidedCapRaised) {
        this.capRaisedBucket = this.findCustomCapRaisedBucket(userProvidedCapRaised)
    }

    findCustomCapRaisedBucket(capRaised) {
        for (let i = 0; i < this.customCapRaisedBuckets.length; i++) {
            const currentBucket = this.customCapRaisedBuckets[i]
            if (capRaised >= currentBucket.min && capRaised < currentBucket.max) {
                return currentBucket
            }
        }

        return this.customCapRaisedBuckets[this.customCapRaisedBuckets.length - 1]
    }

    shouldIncludeInSet(dataEntry) {
        if (!this.capRaisedBucket || !dataEntry.raisedBucket) { return true }

        const { min, max } = this.capRaisedBucket
        const capRaised = dataEntry.totalRaised
        const moreThanMin = min ? capRaised >= min : true
        const lessThanMax = max ? capRaised < max : true
        return moreThanMin && lessThanMax
    }
}

export class AgeFilter {
    queryParamToAgeBuckets = {
        '-1': undefined,
        '0': { min: 0, max: 2 },
        '1': { min: 2, max: 5 },
        '2': { min: 5, max: 10 },
        '3': { min: 10 }
    }

    constructor(age_filter) {
        this.ageBucket = age_filter ? this.queryParamToAgeBuckets[age_filter] : undefined
    }

    shouldIncludeInSet(dataEntry) {
        if (!this.ageBucket || !dataEntry.ageBucket) { return true }

        const { min, max } = this.ageBucket
        const olderThanMin = min ? dataEntry.age >= min : true
        const youngerThanMax = max ? dataEntry.age < max : true
        return olderThanMin && youngerThanMax
    }
}

export class SectorFilter {
    constructor(sector_filter) {
        this.sector = sector_filter
    }

    shouldIncludeInSet(dataEntry) {
        if (!this.sector || !dataEntry.sector || this.sector === '-1') { return true }
        return this.sector === dataEntry.sector
    }
}

export class ValuationFilter {
    queryParamToValuationBuckets = {
        '-1': undefined,
        '0': { min: 0, max: 100000000 },
        '1': { min: 100000000, max: 250000000 },
        '2': { min: 250000000 }
    }

    constructor(valuation_filter) {
        this.valuationBucket = valuation_filter ? this.queryParamToValuationBuckets[valuation_filter] : undefined
    }

    shouldIncludeInSet(dataEntry) {
        if (!this.valuationBucket || !dataEntry.valuationBucket) { return true }

        const { min, max } = this.valuationBucket
        const companyValuation = dataEntry.lastValuation
        const higherThanMin = min ? companyValuation >= min : true
        const lowerThanMax = max ? companyValuation < max : true
        return higherThanMin && lowerThanMax
    }
}