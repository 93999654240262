import React from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { Container, Icon } from 'semantic-ui-react'

import { CONTENT_PIECE_ROUTE, CONTENT_PIECES, colorConstants } from '../_constants'
import { TitledPage, ContentTitle, PageView } from '../_components'

import './ContentPage.css'

export class ContentPage extends React.Component {
  componentDidMount() { PageView() }

  render() {
    return (
      <TitledPage
        title='CONTENT'
        subtitles={[
          "We have over twenty years of experience partnering with great startups across an array of stages, industries, and geographies. This content is a small collection of the lessons and principles that we've learned over that period.",
          "We hope this content, in conjunction with the rest of our tools, can help you to build a better and more sustainable business."
        ]}
        subtitleWidth="60%"
        darkPage={false}
      >
        <ContentLink
          title="REVENUE FOR AGE"
          link={CONTENT_PIECES.REVENUE_PER_AGE}
        />
        <ContentLink
          title="GROWTH"
          link={CONTENT_PIECES.GROWTH_RATE}
        />
        <ContentLink
          title="CAPITAL RAISED"
          link={CONTENT_PIECES.REVENUE_PER_CAPITAL}
        />
        <ContentLink
          title="CAPITAL EFFICIENCY"
          link={CONTENT_PIECES.CAPITAL_EFFICIENCY}
        />
        <ContentLink
          title="EMPLOYEE EFFICIENCY"
          link={CONTENT_PIECES.REVENUE_PER_EMPLOYEE}
        />
      </TitledPage >
    )
  }
}

function ContentLink({ title, link }) {
  return (
    <Container className="content-section-header" style={{ backgroundColor: colorConstants.Toolbox }}>
      <a href={CONTENT_PIECE_ROUTE(link)}>
        {isMobile ? undefined : (<Icon name="arrow alternate circle right outline" size="huge" inverted floated="left" />)}
        <ContentTitle content={title} floated={isMobile ? "" : "left"} />
      </a>
    </Container>
  )
}

ContentPage.propTypes = {
  mobile: PropTypes.bool,
}