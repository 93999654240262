import React from 'react'
import { Dimmer, Segment, Container, Loader, Grid } from 'semantic-ui-react'
import { compose } from 'recompose'

import { TitledPage, ButtonFromDownArrow, ImagePage, PageTitle, PageSubtitle, withAuthenticatedUser } from '../_components'
import { UserHistoryChart } from '../_components/charts'
import { colorConstants, ROUTES } from '../_constants'
import { history, connectToUserSession, connectToCompanyData, connectToUserHistory, connectToCurrentFilters } from '../_helpers'
import { MetricsRanker } from '../_services'

import './UserHistoryPage.css'

const UnconnectedUserHistoryPage = ({ profile, userHistory, currentFilters, companyData }) => {
  const userName = getFirstName(profile.displayName)
  const benchmarkHistory = userHistory
    .map(result => generateBenchmarksForChart(result, companyData, currentFilters))
    .reverse()

  const dimmerActive = companyData.length === 0 ||
    (benchmarkHistory.length > 0 && !benchmarkHistory[0].overallRank)

  return (
    <div>
      {
        benchmarkHistory.length > 0 ?
          <UserHistoryContent
            userName={userName}
            benchmarkHistory={benchmarkHistory}
            dimmerActive={dimmerActive}
          /> :
          <NoHistoryContent userName={userName} />
      }
    </div>
  )
}

export const NoHistoryContent = ({ userName }) => (
  <ImagePage>
    <PageTitle
      id="welcome-title"
      content='YOUR HISTORY'
      darkText={true}
    />
    <PageSubtitle
      content={`Hi, ${userName}. We're working hard to build the best startup toolset possible. You can get started by trying our quick, free, and confidential benchmarking tool.`}
      darkText={true}
    />
    <PageSubtitle
      content="Then, check back over time to see how much progress you've made on our key benchmarks."
      darkText={true}
    />
    <ButtonFromDownArrow
      content="CHECK YOUR SCORES"
      darkButton={false}
      buttonCentered={true}
      onClick={() => history.push(ROUTES.FILTER)}
    />
  </ImagePage>
)

export const UserHistoryContent = ({ userName, benchmarkHistory, dimmerActive }) => (
  <TitledPage
    darkPage={false}
    title="YOUR HISTORY"
    subtitles={[`Hi, ${userName}. Thanks for making our tool a part of your journey, and we hope that you have found this tool helpful towards understanding and improving your business. Here, you can see the history of your benchmarks over time.`]}
    subtitleWidth="80%"
  >
    <div>
      <Dimmer.Dimmable as={Segment} blurring dimmed={dimmerActive}>
        <Dimmer active={dimmerActive}>
          <Loader>Loading</Loader>
        </Dimmer>
        <Container className="user-history-block" style={{ backgroundColor: colorConstants.Toolbox, width: '100%' }}>
          <Grid columns='one' stackable reversed='mobile' relaxed>
            <Grid.Column style={{ backgroundColor: colorConstants.Toolbox, color: colorConstants.White }} >
              <div id='unicorn-metric-block'>
                <UserHistoryChart userHistory={benchmarkHistory} />
              </div>
            </Grid.Column>
          </Grid>
        </Container>
      </Dimmer.Dimmable>
    </div>
    <ButtonFromDownArrow
      content="LEARN ABOUT YOUR SCORES"
      darkButton={false}
      buttonCentered={true}
      onClick={() => history.push(ROUTES.BENCHMARKS)}
    />
  </TitledPage>
)

const getFirstName = (displayName) => displayName ? displayName.split(" ")[0] : ''

const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    minutes = d.getMinutes()

  if (month.length < 2) {
    month = '0' + month
  }

  if (day.length < 2) {
    day = '0' + day
  }

  if (hour.length < 2) {
    hour = '0' + hour
  }

  if (minutes.length < 2) {
    minutes = '0' + minutes
  }

  return [year, month, day].join('-') + ' ' + [hour, minutes].join(':');
}

const generateBenchmarksForChart = (result, companyData, currentFilters) => {
  const metricsRanker = new MetricsRanker(result, companyData, currentFilters)
  const percentileRanks = metricsRanker.getPercentileRanks()
  const overallRank = metricsRanker.getOverallRank()

  const date = new Date(result.timestamp)
  const dateString = formatDate(date)

  return (
    {
      ...percentileRanks,
      overallRank,
      dateString
    }
  )
}

export const UserHistoryPage = compose(
  withAuthenticatedUser,
  connectToUserSession,
  connectToUserHistory,
  connectToCurrentFilters,
  connectToCompanyData
)(UnconnectedUserHistoryPage)