import React from 'react'
import PropTypes from 'prop-types'
import Plot from 'react-plotly.js'

// Also hiding the mode bar via CSS ignore
const chartConfig = {
  showLink: false,
  displaylogo: false,
  responsive: true
}

const defaultLayoutFields = {
  xaxis: {},
  yaxis: {},
  margin: {
    l: 50,
    r: 40,
    b: 40,
    t: 50,
  }
}

export const getPlotlyChartLayout = (customLayout) => {
  const xAxis = customLayout ? customLayout.xaxis : defaultLayoutFields.xaxis
  const yAxis = customLayout ? customLayout.yaxis : defaultLayoutFields.yaxis
  const margin = customLayout ? customLayout.margin : defaultLayoutFields.margin

  const chartLayout = {
    ...customLayout,
    xaxis: { ...xAxis, fixedrange: true },
    yaxis: { ...yAxis, fixedrange: true },
    margin,
    titlefont: { size: 20, bold: 'true' },
  }

  const chartStyle = {
    height: chartLayout.height || '100%',
    width: chartLayout.width || '100%'
  }

  return { chartLayout, chartStyle }
}

export const PlotlyWrapper = (props) => {
  const { layout, data } = props.chartDescription
  const { chartLayout, chartStyle } = getPlotlyChartLayout(layout)

  return (
    <div className='plotly-wrapper'>
      <Plot
        useResizeHandler
        data={data}
        layout={chartLayout}
        config={chartConfig}
        style={chartStyle}
      />
    </div>
  )
}

PlotlyWrapper.propTypes = {
  chartDescription: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    layout: PropTypes.object
  })
}