import React from 'react'

import { ROUTES } from '../_constants'
import { ImagePage, PageTitle, PageSubtitle, ButtonWithDownArrow, PageView } from '../_components'
import { history } from '../_helpers'

export class NotFoundPage extends React.Component {
  componentDidMount() { PageView() }

  render() {
    return (
      <ImagePage>
        <PageTitle
          id="welcome-title"
          content='NOT FOUND'
          darkText={true}
        />
        <PageSubtitle
          content="Oops! We couldn't find that piece of content for you. We're working hard to build the best startup toolset possible, so visit our home page to see all of our offerings!"
          darkText={true}
        />
        <div className='filter-button'>
          <ButtonWithDownArrow
            content="VISIT HOME"
            onClick={() => history.push(ROUTES.HOME)}
            darkButton={false}
            buttonCentered={false}
          />
        </div>
      </ImagePage>
    )
  }
}