import Papa from 'papaparse'

const DATA_INDICES = {
  TotalRaised: 0,
  NumEmployees: 1,
  Revenue: 2,
  YoYGrowth: 3,
  Age: 4,
  LastValuation: 5,
  CapEfficiency: 6,
  AgeBucket: 7,
  ValuationBucket: 8,
  RaisedBucket: 9,
  Sector: 10
}

export class DataFetcher {
  constructor(dataFileLocation, filterParams) {
    this.dataFileLocation = dataFileLocation
    this.filterParams = filterParams
  }

  fetch(dataResultsCallback, errorCallback) {
    const dataSetterFunction = (data) => {
      const cleanedData = this._cleanDataEntries(data)
      dataResultsCallback(cleanedData)
    }

    Papa.parse(this.dataFileLocation, {
      download: true,
      complete: function (results) {
        dataSetterFunction(results.data)
      },
      error: function (err) {
        errorCallback(err)
      }
    })

    return
  }

  _cleanDataEntries(rawData) {
    if (!rawData) { return [] }

    let cleanData = rawData.map((row) => {
      return {
        totalRaised: row[DATA_INDICES.TotalRaised],
        numEmployees: row[DATA_INDICES.NumEmployees],
        revenue: row[DATA_INDICES.Revenue],
        yoyGrowth: row[DATA_INDICES.YoYGrowth],
        age: row[DATA_INDICES.Age],
        lastValuation: row[DATA_INDICES.LastValuation],
        capEfficiency: row[DATA_INDICES.CapEfficiency],
        ageBucket: row[DATA_INDICES.AgeBucket],
        valuationBucket: row[DATA_INDICES.ValuationBucket],
        raisedBucket: row[DATA_INDICES.RaisedBucket],
        sector: row[DATA_INDICES.Sector]
      }
    })

    return cleanData
  }
}