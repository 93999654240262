import React from 'react'
import PropTypes from 'prop-types'
import NormalDistribution from 'normal-distribution'
import { compose } from 'recompose'
import { isEqual } from 'underscore'

import { connectToCompanyData, connectToLastUserInput } from '../../_helpers'

export const CHART_COLORS = {
  OldLavender: '#726787',
  WhiteSmoke: '#F7F7F7',
  PurpleHeart: '#6737A3',
  PaleLavender: '#DCCFEC',
  LightPastelPurple: '#A997DF',
  AquaTrendLine: 'rgba(0, 255, 255, 0.4)',
  BlueTrendLine: 'rgba(0, 128, 255, 0.6)',
  DarkPastelPurple: "#A06CD5",
  SpanishViolet: "#4B296B"
}

export const REVENUE_BUCKETS = [
  { title: '0-5', min: 0, max: 5000000 },
  { title: '5-10', min: 5000000, max: 10000000 },
  { title: '10-25', min: 10000000, max: 25000000 },
  { title: '25-100', min: 25000000, max: 100000000 },
  { title: '100-250', min: 100000000 }
]

export const findCorrespondingBucket = (revenue) => {
  const bucket = REVENUE_BUCKETS.find(bucket => {
    const lessThanMax = bucket.max ? revenue < bucket.max : true
    return revenue >= bucket.min && lessThanMax
  })

  return bucket
}

export const generateScatterAndTrendTraces = (xData, yData) => {
  const scatterTrace = {
    x: xData,
    y: yData,
    mode: 'markers',
    type: 'scatter',
    marker: {
      size: 4,
      color: CHART_COLORS.SpanishViolet
    },
    hoverinfo: 'x+y'
  }

  const trendLine = getTrendLine(xData, yData)
  const trendLineTrace = {
    x: trendLine.xValues,
    y: trendLine.yValues,
    type: 'scatter',
    mode: 'lines',
    line: {
      color: CHART_COLORS.BlueTrendLine
    },
    hoverinfo: 'x+y'
  }

  return { scatterTrace, trendLineTrace }
}

export const generateHistogramWithDistributionTraces = (data, bins) => {
  const histogramTrace = {
    x: data,
    type: 'histogram',
    histnorm: 'percent',
    marker: { color: CHART_COLORS.SpanishViolet },
    xbins: bins,
    hoverinfo: 'x'
  }

  const normalDistLine = getNormalDistLine(data, histogramTrace.xbins)
  const normalDistTrace = {
    x: normalDistLine.xValues,
    y: normalDistLine.yValues,
    mode: 'lines',
    type: 'scatter',
    line: {
      color: CHART_COLORS.AquaTrendLine,
    },
    hoverinfo: 'x'
  }

  return { histogramTrace, normalDistTrace }
}

export const getTrendLine = (values_x, values_y) => {
  var sum_x = 0;
  var sum_y = 0;
  var sum_xy = 0;
  var sum_xx = 0;
  var count = 0;

  var x = 0;
  var y = 0;
  var values_length = values_x.length;

  if (values_length !== values_y.length) {
    throw new Error('The parameters values_x and values_y need to have same size!');
  }

  if (values_length === 0) {
    return { trendDataX: [], trendDataY: [] }
  }

  for (var v = 0; v < values_length; v++) {
    x = values_x[v];
    y = values_y[v];
    sum_x += x;
    sum_y += y;
    sum_xx += x * x;
    sum_xy += x * y;
    count++;
  }

  const m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
  const b = (sum_y / count) - (m * sum_x) / count;
  var result_values_x = [];
  var result_values_y = [];

  for (v = 0; v < values_length; v++) {
    x = values_x[v];
    y = x * m + b;
    result_values_x.push(x);
    result_values_y.push(y);
  }

  return { xValues: result_values_x, yValues: result_values_y }
}

export const getNormalDistLine = (dataPoints, xRange) => {
  if (!dataPoints || dataPoints.length === 0) {
    return { mean: NaN, stdDev: NaN }
  }

  const count = dataPoints.length
  const mean = dataPoints.reduce((a, b) => a + b, 0) / count

  let totalDeviationSquared = 0
  for (let i = 0; i < count; i++) {
    const deviation = mean - dataPoints[i]
    totalDeviationSquared += deviation * deviation
  }

  const stdDev = Math.sqrt(totalDeviationSquared / count)
  const normDist = new NormalDistribution(mean, stdDev)

  let xValues = []
  let yValues = []
  const xRangeWidth = xRange.end - xRange.start
  for (let i = 0; i < xRangeWidth; i++) {
    const xValue = xRange.start + i
    xValues[i] = xValue
    yValues[i] = normDist.pdf(xValue) * 1000
  }

  return { xValues, yValues }
}

export const isValidNum = value => value && !isNaN(value)

export const axisLabelTitle = (title) => ({
  text: title,
  font: {
    size: 14,
    bold: true
  }
})

export const dashboardChartWrapper = Component => {
  class DashboardChartWrapper extends React.Component {

    static propTypes = {
      companyData: PropTypes.array,
      lastUserInput: PropTypes.object
    }

    shouldComponentUpdate(nextProps) {
      const { companyData, lastUserInput } = this.props
      return companyData.length === 0 ||
        !isEqual(lastUserInput, nextProps.lastUserInput)
    }

    render() {
      const { companyData, lastUserInput } = this.props
      if (companyData.length === 0) {
        return (<Component companyData={[]} />)
      }

      return (
        <Component
          companyData={companyData}
          lastUserInput={lastUserInput}
        />
      )
    }
  }

  const connectedComponent = compose(
    connectToCompanyData,
    connectToLastUserInput
  )(DashboardChartWrapper)

  return connectedComponent
}
