import React from 'react'

import { PlotlyWrapper } from './plotlyWrapper'

import { axisLabelTitle } from './chartUtilities'
import { colorConstants } from '../../_constants'
import { isMobile, isBrowser } from 'react-device-detect'

const standardStyle = (color) => (
  {
    marker: {
      color,
      size: 3
    },
    line: {
      color,
      width: 1,
    }
  }
)

const rankStyles = {
  'Overall': {
    marker: { color: colorConstants.RedOrange, size: 8 },
    line: { color: colorConstants.RedOrange, width: 3 }
  },
  'Revenue for Age': standardStyle(colorConstants.Toolbox),
  'YoY Growth': standardStyle(colorConstants.LightToolbox),
  'Revenue for Capital Raised': standardStyle(colorConstants.LightSteelBlue),
  'Capital Efficiency': standardStyle(colorConstants.EerieBlack),
  'Revenue per Employee': standardStyle(colorConstants.PurpleTaupe)
}


export function UserHistoryChart(props) {
  const { userHistory, isForMobile } = props
  const plotHeight = isForMobile ? 400 : 460
  const datePoints = userHistory.map(row => row.dateString)
  const rankNames = {
    'Overall': row => +row.overallRank,
    'Revenue for Age': row => +row.pointInTimeRevenueRank,
    'YoY Growth': row => +row.yoyGrowth,
    'Revenue for Capital Raised': row => +row.revenueWithinCapRaised,
    'Capital Efficiency': row => +row.capEfficiency,
    'Revenue per Employee': row => +row.revPerEmployee
  }


  const traces = Object.keys(rankNames).map(name => {
    const yFunction = rankNames[name]
    const style = rankStyles[name]
    const hoverinfo = isMobile ? 'name' : 'x+y'

    return {
      x: datePoints,
      y: userHistory.map(row => yFunction(row)),
      type: 'scatter',
      mode: 'lines+markers',
      hoverinfo,
      name,
      ...style
    }
  })

  const mobileLegend = {
    legend: {
      x: 1,
      y: 0.5
    }
  }
  const legend = isMobile ? mobileLegend : {}

  const chartDescription = {
    layout: {
      xaxis: {
        autoRange: true,
      },
      yaxis: {
        title: axisLabelTitle('Percentile Rank (%)'),
        range: [0, 101]
      },
      height: plotHeight,
      showlegend: isBrowser,
      margin: {
        l: 60,
        r: 40,
        b: 40,
        t: 20,
      }
    },
    data: traces,
    ...legend
  }

  return (
    <PlotlyWrapper chartDescription={chartDescription} />
  )
}