import React from 'react'

import { PlotlyWrapper } from './plotlyWrapper'
import { REVENUE_BUCKETS, findCorrespondingBucket, CHART_COLORS, isValidNum, axisLabelTitle, dashboardChartWrapper } from './chartUtilities'

export const generateCapitalEfficiencyTrace = (revenueBucket, filteredData, traceColor) => {
  const yData = filteredData.filter((row) => {
    const lessThanMax = revenueBucket.max ? row.revenue < revenueBucket.max : true
    return row.revenue >= revenueBucket.min && lessThanMax
  }).map(row => row.capEfficiency)

  const trace = {
    name: revenueBucket.title,
    y: yData,
    type: 'box',
    marker: {
      size: 2,
      color: traceColor
    },
    line: {
      width: 1
    },
    hoverinfo: 'none'
  }

  return trace
}

export function UnconnectedCapitalEfficiencyChart(props) {
  const { companyData, lastUserInput } = props
  const filteredData = companyData.filter(row => isValidNum(row.revenue) && isValidNum(row.capEfficiency))

  const traceColors = [
    CHART_COLORS.OldLavender,
    CHART_COLORS.SpanishViolet,
    CHART_COLORS.PurpleHeart,
    CHART_COLORS.DarkPastelPurple,
    CHART_COLORS.LightPastelPurple,
  ]

  let traces = []
  for (let i = 0; i < REVENUE_BUCKETS.length; i++) {
    traces[i] = generateCapitalEfficiencyTrace(REVENUE_BUCKETS[i], filteredData, traceColors[i])
  }

  const calculateCapEfficiency = ({ revenue, yoy_growth, burn_rate }) => {
    return (revenue * yoy_growth / 100) / burn_rate
  }

  const lastInputTrace = lastUserInput ? {
    x: [findCorrespondingBucket(lastUserInput.revenue).title],
    y: [calculateCapEfficiency(lastUserInput)],
    type: 'scatter',
    hoverinfo: 'text',
    text: `Your Data: %${calculateCapEfficiency(lastUserInput)}`,
    marker: {
      color: 'red',
      size: 10
    }
  } : {}

  const yMax = Math.max(120, lastUserInput ? calculateCapEfficiency(lastUserInput) + 30 : 0)
  const chartDescription = {
    layout: {
      xaxis: { title: axisLabelTitle('Annual Revenue Range ($M)') },
      yaxis: {
        title: axisLabelTitle('Capital Efficiency'),
        range: [0, yMax],
        tickmode: 'linear',
        tick0: 0,
        dtick: 20
      },
      height: 350,
      margin: {
        l: 50,
        r: 40,
        b: 60,
        t: 20,
        pad: 1
      },
      showlegend: false,
    },
    data: traces.concat([lastInputTrace])
  }

  return (
    <PlotlyWrapper chartDescription={chartDescription} />
  )
}

export const CapitalEfficiencyChart = dashboardChartWrapper(UnconnectedCapitalEfficiencyChart)