import React from 'react'
import ReactGA from 'react-ga'
import { Form, Message, Button } from 'semantic-ui-react'

import { connectToFirebaseAuth } from '../../_helpers'
import { trackableEvents } from '../../_constants'

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  submitted: false
}

class UnconnectedManualRegistration extends React.Component {
  constructor(props) {
    super(props)
    this.state = INITIAL_STATE
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({
      [name]: value,
      submitted: false
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { firstName, lastName, email, passwordOne } = this.state
    const { firebase, onSuccessfulLogin } = this.props
    this.setState({ submitted: true })

    const displayName = `${firstName} ${lastName}`
    firebase.createUser({ email, password: passwordOne }, { displayName, email })
      .then(response => {
        onSuccessfulLogin(response)
        ReactGA.event({
          category: trackableEvents.SIGNUP,
          action: `Successful manual registration`,
        })
      })
      .catch(error => this.setState({ error }))
  }

  render() {
    const { firstName, lastName, email, passwordOne, passwordTwo, submitted, error } = this.state
    const { toggleToSignIn } = this.props
    const isValidForm = firstName !== '' &&
      lastName !== '' &&
      email !== '' &&
      passwordOne !== '' &&
      passwordTwo !== ''

    return (
      <div className='user-login-form partial-width'>
        {submitted && error && <Message error header='Registration Failed' content={error ? error.message : ''} />}
        <Form onSubmit={this.handleSubmit}>
          <Form.Group widths='equal'>
            <Form.Input label='First Name' name="firstName" value={firstName} onChange={this.handleChange} placeholder='First Name' />
            <Form.Input label='Last Name' name="lastName" value={lastName} onChange={this.handleChange} placeholder='Last Name' />
          </Form.Group>
          <Form.Field>
            <Form.Input label='Email' name="email" value={email} onChange={this.handleChange} placeholder='Email Address' />
          </Form.Field>
          <Form.Field>
            <Form.Input type='password' label='Password' name="passwordOne" value={passwordOne} onChange={this.handleChange} placeholder='Password' />
          </Form.Field>
          <Form.Field>
            <Form.Input type='password' label='Re-enter Password' name="passwordTwo" value={passwordTwo} onChange={this.handleChange} placeholder='Password' />
          </Form.Field>
          <Button primary floated='right' disabled={!isValidForm}>Sign Up</Button>
        </Form>
        <Button onClick={toggleToSignIn} floated='left'>Sign In</Button>
      </div>
    )
  }
}

const ManualRegistration = connectToFirebaseAuth(UnconnectedManualRegistration)
export { ManualRegistration }