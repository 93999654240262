import React from 'react'

import { Menu, Dropdown, Segment, Header, Grid, Divider, Modal } from 'semantic-ui-react'
import { history, directToExternalSite, connectToUserSession, hasAuthenticatedUser } from '../_helpers'

import { LoginWrapper, LogoutWrapper } from './userLogins'
import { ROUTES, colorConstants } from '../_constants'
import { FeedbackWindow } from './feedbackWindow'
import { isMobile } from 'react-device-detect'

const headerItemStyle = {
  color: colorConstants.EerieBlack
}

const headerStyle = {
  backgroundColor: colorConstants.White
}

export class UnconnectedSiteHeader extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.auth.isLoaded && nextProps.profile.isLoaded
  }

  render() {
    const { auth, profile } = this.props
    return (
      <Menu fixed='top' borderless style={headerStyle}>
        <DropDownMenu />
        <Menu.Item name='FULL IN PARTNERS' style={headerItemStyle} onClick={() => directToExternalSite(ROUTES.FULLINHOME)} />
        <Menu.Menu position='right'>
          <UserSection auth={auth} profile={profile} />
        </Menu.Menu>
      </Menu>
    )
  }
}

export const DropDownMenu = () => {
  return (
    <Menu.Item>
      <Dropdown basic icon="content" style={headerItemStyle}>
        <Dropdown.Menu>
          <Dropdown.Item text="Home" onClick={() => history.push(ROUTES.HOME)} />
          <Dropdown.Item text="Benchmarks" onClick={() => history.push(ROUTES.BENCHMARKS)} />
          <Dropdown.Item text="Content" onClick={() => history.push(ROUTES.CONTENT_HOME)} />
          <Dropdown.Item text="History" onClick={() => history.push(ROUTES.USER_HISTORY)} />
          <Dropdown.Item text="About" onClick={() => history.push(ROUTES.ABOUT)} />
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  )
}

export const UserSection = ({ auth, profile }) => {
  const authenticated = hasAuthenticatedUser(auth)
  return (
    authenticated ?
      <AuthUserSection auth={auth} profile={profile} /> :
      <NonAuthUserSection />
  )
}

export const NonAuthUserSection = () => {
  const openableMenuItem = (handleOpen) => (
    <Menu.Item onClick={handleOpen} style={headerItemStyle}>Sign In / Register</Menu.Item>
  )

  return (<LoginWrapper openableTrigger={openableMenuItem} />)
}

export const getFirstName = (displayName) => displayName ? displayName.split(" ")[0] : ''

export const AuthUserSection = (props) => (
  <Menu.Item style={headerItemStyle}>
    <Dropdown text={`Hi ${getFirstName(props.profile.displayName)}`}>
      <Dropdown.Menu>
        <LogoutWrapper openableTrigger={(handleOpen) => (<Dropdown.Item text='Logout' onClick={handleOpen} />)} />
      </Dropdown.Menu>
    </Dropdown>
  </Menu.Item>
)

export const SiteHeader = connectToUserSession(UnconnectedSiteHeader)

export const SiteFooter = ({ darkText }) => {
  const color = darkText ? colorConstants.EerieBlack : colorConstants.White
  const style = { color, margin: '8px' }
  const columnWidth = isMobile ? 6 : 4

  return (
    <div className='site-footer'>
      <Divider />
      <Segment basic textAlign='center'>
        <Header size='tiny' style={style} content='Built by Full In Partners' />
        <Header size='tiny' style={style} content='551 Madison Ave. Suite 1101, New York, NY, 10011' />
        <Grid textAlign='center'>
          <Grid.Row>
            <Grid.Column width={columnWidth}>
              <a href={ROUTES.ABOUT}>
                <Header size='tiny' style={style} content='About' />
              </a>
            </Grid.Column>
            <Grid.Column width={columnWidth}>
              <FeedbackWindow
                openableTrigger={(handleOpen) => <Header size='tiny' style={style} content='Feedback' onClick={handleOpen} />}
              />
            </Grid.Column>
            <Grid.Column width={columnWidth}>
              <PrivacyModal textStyle={style} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  )
}


function PrivacyModal({ textStyle }) {
  return (
    <Modal
      trigger={<Header size='tiny' style={textStyle} content='Privacy' />}
      centered={false}
      size='small'
    >
      <Modal.Header centered>Privacy Policy</Modal.Header>
      <Modal.Content className="feedback-modal" >
        <Modal.Description>
          <Header size='tiny' style={textStyle} content='Your Data' />
          <p>
            We at Full In Partners care about your privacy and the security of
            your information. We understand that the data you're providing us with is both highly significant
            and highly confidential and therefore, we want you to be familar with how we collect and use the information
            you provide. We do not share your personal information with any third party under any circumstances. We will
            use your personal information to a) improve the quality of the Service b) improve Full In Partners processes.
            Prior to registration, your data will be completely anonymous. After registration with the Service, Full In Partners
            may contact you to a) solicit feedback on the Service or b) inquire about potential partnership opportunities.
          </p>
          <Header size='tiny' style={textStyle} content='Third-Party Analytics' />
          <p>
            We may directly collect analytics data, or use third-party analytics tools, to help us
            measure traffic and usage trends for the Service. These tools collect information sent by
            your browser or mobile device, including the pages you visit and other information that
            assists us in improving the Service. We collect and use this analytics information in aggregate
            form such that if cannot reasonably be manipulated to identify any particular individual user.
          </p>
        </Modal.Description>
      </Modal.Content>
    </Modal>

  )
}