import React from 'react'
import ReactGA from 'react-ga'
import { Modal, Header } from "semantic-ui-react"

import { trackableEvents } from '../_constants'
import { ButtonWithDownArrow } from './buttons'

export class FeedbackWindow extends React.Component {
    constructor(props) {
        super(props)
        this.openableTrigger = props.openableTrigger
        this.state = { modalOpen: false }
    }

    handleOpen = () => {
        this.setState({ modalOpen: true })
    }

    handleClose = () => {
        this.setState({ modalOpen: false })
    }

    postJson(url, apiKey, postBody) {
        let body = JSON.stringify(postBody)
        let headers = new Headers({
            'Accept': '*/*',
            'Content-Type': 'application/json',
            'Connection': 'keep-alive',
            'X-Api-Key': apiKey
        })

        let options = {
            method: 'POST',
            mode: 'cors',
            headers,
            body
        }

        ReactGA.event({
            category: trackableEvents.SEND_FEEDBACK,
            action: `Site feedback submitted`,
        })

        return fetch(url, options)
    }

    submit_feedback = () => {
        let feedbackObj = document.getElementsByName("input")[0]
        let usefulObj = document.getElementsByName("useful")[0]

        let url = "https://6rjx3nrzy9.execute-api.us-east-2.amazonaws.com/default/metricsSiteRestAPI/feedback"
        const apiKey = "lgiiwHQ2DR8crj7tPvT3d27JkXJtI3tb6LiLHKK3"
        let postBody = {
            'was_helpful': usefulObj.value,
            'feedback': feedbackObj.value
        }
        console.log(postBody)

        this.postJson(url, apiKey, postBody)
        this.handleClose()
    }

    render() {
        return (
            <Modal
                trigger={this.openableTrigger(this.handleOpen)}
                centered={false}
                open={this.state.modalOpen}
                onClose={this.handleClose}
                size='small'
                id="feedback-modal"
            >
                <div>
                    <Header content="Was this tool helpful to you?" />
                    <div className="hiddenradio">
                        <label id="radio-input">
                            <input type="radio" name="useful" value="1"></input>
                            <img alt="" width="50" height="50" src="/assets/images/check.png" />
                        </label>

                        <label id="radio-input">
                            <input type="radio" name="useful" value="0"></input>
                            <img alt="" width="50" height="50" src="/assets/images/x.png" />
                        </label>
                    </div>
                    <textarea name="input" placeholder="Interested in leaving feedback?" />
                    <div className="submit-feedback-button">
                        <ButtonWithDownArrow
                            content="SUBMIT FEEDBACK"
                            darkButton={false}
                            onClick={this.submit_feedback}
                            buttonCentered={true}
                        />
                    </div>
                </div>
            </Modal>
        )
    }
}