import React from 'react'
import PropTypes from 'prop-types'
import {
  Header,
  Responsive,
  Segment,
  List
} from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'
import { compose } from 'recompose'

import { ROUTES, colorConstants } from '../_constants'
import { history, connectToUserSession, connectToLastUserInput, hasAuthenticatedUser } from '../_helpers'
import { ImagePage, PageTitle, PageSubtitle, ButtonWithDownArrow, PageView } from '../_components'

import './HomePage.css'


const NewHomepage = () => (
  <ImagePage>
    <PageTitle
      id="welcome-title"
      content='ARE YOU BUILDING A SUCCESSFUL BUSINESS?'
      darkText={true}
    />
    <PageSubtitle
      content="Try our quick, free, and confidential health check to help ensure you are doing everything possible to improve your company's position in today's competitive startup landscape"
      darkText={true}
    />
    <div className='filter-button'>
      <ButtonWithDownArrow
        content="START YOUR HEALTH CHECK"
        onClick={() => history.push(ROUTES.FILTER)}
        darkButton={false}
        buttonCentered={isMobile}
      />
    </div>
  </ImagePage>
)

const returnStatsTextStyle = {
  fontSize: 14,
  textAlign: 'left',
  fontFamily: "Trebuchet MS, Helvetica, sans-serif",
  lineHeight: '1em',
  color: colorConstants.PurpleTaupe
}

const UnconnectedReturningHomepage = (props) => (
  <ImagePage>
    <PageTitle
      id="welcome-title"
      content='WELCOME BACK'
      darkText={true}
    />
    <PageSubtitle
      content="We're glad to see you tracking your progress towards building a sustainable company. We hope you're getting close, so check your health again to see how you've done"
      darkText={true}
    />
    <Segment className='white-button' piled padded size='tiny'>
      <Header style={returnStatsTextStyle} content="Last time you visited, your stats were: " inverted />
      <List horizontal={!isMobile} inverted>
        <List.Item>
          <Header style={returnStatsTextStyle} content='Founded:' size='small' />
          {props.lastUserInput.year_founded}
        </List.Item>
        <List.Item>
          <Header style={returnStatsTextStyle} content='Revenue:' size='small' />
          {props.lastUserInput.revenue}
        </List.Item>
        <List.Item>
          <Header style={returnStatsTextStyle} content='YoY Growth:' size='small' />
          {props.lastUserInput.yoy_growth}%
           </List.Item>
        <List.Item>
          <Header style={returnStatsTextStyle} content='Total Raised:' size='small' />
          {props.lastUserInput.cap_raised}
        </List.Item>
        <List.Item>
          <Header style={returnStatsTextStyle} content='Employees:' size='small' />
          {props.lastUserInput.num_employees}
        </List.Item>
        <List.Item>
          <Header style={returnStatsTextStyle} content='Monthly Burn:' size='small' />
          {props.lastUserInput.burn_rate}
        </List.Item>
      </List>
    </Segment>
    <div className='filter-button'>
      <ButtonWithDownArrow
        content="START YOUR HEALTH CHECK"
        onClick={() => history.push(ROUTES.FILTER)}
        darkButton={false}
      />
    </div>
  </ImagePage>
)

const ReturningHomepage = connectToLastUserInput(UnconnectedReturningHomepage)

NewHomepage.propTypes = {
  mobile: PropTypes.bool,
}

ReturningHomepage.propTypes = {
  mobile: PropTypes.bool,
}

class UnconnectedHomepage extends React.Component {
  componentDidMount() { PageView() }

  render() {
    const { auth, lastUserInput } = this.props
    const isReturningUser = hasAuthenticatedUser(auth) && lastUserInput
    return (
      <div>
        {isReturningUser ?
          <ReturningHomepage /> :
          <NewHomepage />}
      </div>
    )
  }
}

const Homepage = compose(
  connectToUserSession,
  connectToLastUserInput
)(UnconnectedHomepage)

class DesktopContainer extends React.Component {
  state = {}

  render() {
    const { children } = this.props

    /* eslint-disable jsx-a11y/anchor-has-content */
    return (
      <Responsive>
        <Homepage />
        {children}
      </Responsive >
    )
    /* eslint-enable jsx-a11y/anchor-has-content */
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const HomepageLayout = () => (
  <ResponsiveContainer />
)

export { HomepageLayout as HomePage }