import React from 'react'
import { Button, Image } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect'

export function ButtonWithDownArrow({ content, onClick, disabled, darkButton, buttonCentered, arrowSize }) {
    const buttonColor = darkButton ? "purple-button" : "white-button"
    const buttonClassName = "button-with-text " + buttonColor
    const arrowImage = darkButton ?
        "/assets/images/down_white_arrow.png" :
        "/assets/images/down_dark_arrow.png"

    const imageSize = arrowSize ? arrowSize : 'tiny'
    const arrow = (
        <Image
            size={imageSize}
            src={arrowImage}
            onClick={onClick}
            className='down-arrow'
        />
    )

    const fillerImage = buttonCentered ? arrow : undefined

    return (
        <div className='centered-down-button'>
            <div className='hidden'>
                {fillerImage}
            </div>
            <Button
                content={content}
                className={buttonClassName}
                onClick={onClick}
                disabled={disabled}
            />
            {arrow}
        </div>
    )
}


export function ButtonFromDownArrow({ content, onClick, disabled, darkButton, buttonCentered }) {
    const buttonColor = darkButton ? "purple-button" : "white-button"
    const buttonClassName = "button-with-text " + buttonColor
    const imageSize = isMobile ? 'tiny' : 'small'
    const arrowImage = darkButton ?
        "/assets/images/incoming_white_arrow.png" :
        "/assets/images/incoming_purple_arrow.png"


    const arrow = (
        <Image
            size={imageSize}
            src={arrowImage}
            onClick={onClick}
            className='incoming-arrow'
        />
    )

    const fillerImage = buttonCentered ? arrow : undefined

    return (
        <div className='centered-from-button'>
            {arrow}
            <Button
                content={content}
                className={buttonClassName}
                onClick={onClick}
                disabled={disabled}
            />
            <div className='hidden'>
                {fillerImage}
            </div>
        </div>
    )
}