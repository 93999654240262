import { currentFiltersConstants } from '../_constants'

export const currentFiltersActions = {
    setFilter,
    reset
}

function setFilter(filterName, filterValue) {
    return dispatch =>
        dispatch(request())

    function request() { return { type: currentFiltersConstants.SET_FILTER, filterName, filterValue } }
}

function reset() {
    return dispatch =>
        dispatch(request())

    function request() { return { type: currentFiltersConstants.RESET } }
}