import React, { Component } from 'react'
import { Form, Button, Message } from 'semantic-ui-react'
import { connectToFirebaseAuth } from '../../_helpers'

// import { firebase } from '../../_services'

const INITIAL_STATE = {
  email: '',
  submitted: false,
  error: null,
}

class UnconnectedPasswordForgetForm extends Component {
  constructor(props) {
    super(props)

    this.handleClose = props.handleClose
    this.toggleToSignIn = props.toggleToSignIn
    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    event.preventDefault()

    const { email } = this.state
    this.setState({ submitted: true })

    this.props.firebase.resetPassword({ email })
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        this.handleClose()
      })
      .catch(error => {
        this.setState({ error })
      })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, error, submitted } = this.state
    const isValidForm = email !== ''

    return (
      <div className='user-login-form partial-width'>
        {submitted && error && <Message error header='Password Reset Failed' content={error ? error.message : ''} />}
        <Form onSubmit={this.onSubmit}>
          <Form.Field>
            <Form.Input label='Email' name="email" value={email} onChange={this.onChange} placeholder='Email Address' />
          </Form.Field>
          <Button primary floated='right' disabled={!isValidForm}>Reset Password</Button>
          <Button onClick={this.toggleToSignIn} floated='left'>Sign In</Button>
        </Form>
      </div>
    )
  }
}

const PasswordForgetForm = connectToFirebaseAuth(UnconnectedPasswordForgetForm)
export { PasswordForgetForm }