import React from 'react'
import { Router, Route, Switch } from "react-router-dom"

import { history } from './_helpers'
import { SiteHeader, ScrollToTop } from './_components'
import { ROUTES } from './_constants'

import { HomePage } from './HomePage'
import { DataFormPage } from './DataFormPage'
import { ResultsPage } from './ResultsPage'
import { AboutPage } from './AboutPage'
import { UserHistoryPage } from './UserHistoryPage'
import { BenchmarksPage } from './BenchmarksPage'
import { ContentPage } from './ContentPage'
import { ContentPiecePage } from './ContentPiecePage'
import { NotFoundPage } from './NotFoundPage'

import './App.css'

function App() {
  return (
    <Router history={history}>
      <ScrollToTop>
        <div className='page-root'>
          <SiteHeader />
          <Switch>
            <Route exact path={ROUTES.HOME} component={HomePage} />
            <Route path={ROUTES.FILTER} component={DataFormPage} />
            <Route path={ROUTES.RESULTS} component={ResultsPage} />
            <Route path={ROUTES.USER_HISTORY} component={UserHistoryPage} />
            <Route path={ROUTES.ABOUT} component={AboutPage} />
            <Route path={ROUTES.BENCHMARKS} component={BenchmarksPage} />
            <Route path={ROUTES.CONTENT_PIECE} component={ContentPiecePage} />
            <Route path={ROUTES.CONTENT_HOME} component={ContentPage} />
            <Route path='*' component={NotFoundPage} status={404} />
          </Switch>
        </div>
      </ScrollToTop>
    </Router>
  )
}

export default App