import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import localStorage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import { persistReducer } from 'redux-persist'

import { userHistory } from './userHistory.reducer'
import { currentFilters } from './currentFilters.reducer'

const rootReducer = combineReducers({
  userHistory: persistReducer(
    { key: 'userHistory', storage: localStorage, stateReconciler: hardSet },
    userHistory
  ),
  currentFilters: persistReducer(
    { key: 'currentFilters', storage: localStorage, stateReconciler: hardSet },
    currentFilters
  ),
  firebase: persistReducer(
    { key: 'firebaseState', storage: localStorage, stateReconciler: hardSet },
    firebaseReducer
  )
})

export default rootReducer